import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  useTheme,
} from '@mui/material';

import useStyles from './styles';

function ChatThreadItem(props) {
  const { thread, user } = props;
  const otherUser = user.id === thread.first_user.id ? thread.second_user : thread.first_user;

  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to ChatMessagesPage with user1Id and user2Id as params
    navigate(`/chat/messages/${otherUser.id}`);
  };

  const getDateString = () => {
    const timestamp = new Date(thread.latest_message_timestamp);
    const now = new Date();
    const timeDiff = now.getTime() - timestamp.getTime();
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const daysDiff = Math.floor(timeDiff / oneDay);

    if (daysDiff === 0) {
      // If the date is today, return the time in hh:mm am/pm format
      return timestamp.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    }
    if (daysDiff > 0 && daysDiff < 7) {
      // If the date is within the last 7 days, return the day in 3 letters
      return timestamp.toLocaleDateString('en-US', { weekday: 'short' });
    }
    // If the date is before that, return a date in "MMM DD" format
    return timestamp.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  return (
    <ListItem
      sx={[
        styles.chatThreadItem,
        !thread.latest_message_seen ? styles.chatThreadItemUnread : styles.chatThreadItemRead]}
      onClick={handleClick}
    >
      <ListItemAvatar>
        <Avatar sx={styles.chatThreadAvatar} src={otherUser.profile_picture.image}>
          {otherUser.first_name.charAt(0)}
        </Avatar>
      </ListItemAvatar>
      <Box sx={styles.chatThreadNameAndMsg}>
        <ListItemText
          primary={`${otherUser.first_name} ${otherUser.last_name}`}
          secondary={`${thread.latest_message}`}
        />
      </Box>
      <Box sx={styles.chatThreadDate}>
        {getDateString()}
      </Box>
    </ListItem>
  );
}

ChatThreadItem.propTypes = {
  thread: PropTypes.oneOfType([PropTypes.object]),
  user: PropTypes.oneOfType([PropTypes.object]),
};

ChatThreadItem.defaultProps = {
  thread: null,
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

export default connect(mapStateToProps)(ChatThreadItem);
