import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Avatar,
  Rating,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Define your styles in a separate file (styles.js) and import here
import styles from './styles';

function UserReviews({ reviews }) {
  const classes = makeStyles(styles)();

  if (reviews.length === 0) {
    return (
      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
        No reviews yet.
      </Typography>
    );
  }

  return (
    <Box className={classes.container}>
      <div>
        {reviews.map((review) => (
          <div key={review.id} className={classes.reviewItem}>
            <div className={classes.userInfo}>
              <Avatar
                className={classes.userAvatar}
                src={review.reviewed_by.profile_picture.image}
                alt={review.reviewed_by.first_name}
              />
              <Typography variant="subtitle1">
                {`${review.reviewed_by.first_name} ${review.reviewed_by.last_name}`}
              </Typography>
            </div>

            <div className={classes.ratingStars}>
              <Rating value={review.rating} readOnly />
            </div>

            <div className={classes.reviewText}>
              <Typography variant="body1">{review.message}</Typography>
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
}

UserReviews.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};

export default UserReviews;
