import React, { useState, useEffect } from 'react';
import {
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { request } from '../../common/utils';
import AdminTable from '../common/admin-table';
import AdminModal from '../common/admin-modal';
import styles from './styles';

const productHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'price', label: 'Price' },
  { id: 'deposit', label: 'Deposit' },
  { id: 'tags', label: 'Tags' },
  { id: 'status', label: 'Status' },
  { id: 'owner_name', label: 'Owner Name' },
  { id: 'created_at', label: 'Created At', type: 'datetime-local' },
  { id: 'updated_at', label: 'Updated At', type: 'datetime-local' },
];

const productFields = [
  { id: 'name', label: 'Name', type: 'text' },
  { id: 'price', label: 'Price', type: 'number' },
  { id: 'deposit', label: 'Deposit', type: 'number' },
  { id: 'tags', label: 'Tags', type: 'text' },
  { id: 'status', label: 'Status', type: 'text' },
  { id: 'owner_name', label: 'Owner Name', type: 'text' },
];

function ProductManagement() {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchProducts = async () => {
    try {
      const res = await request('/a/get-all-products', 'GET');
      setFilteredProducts(res.data);
    } catch (err) {
      console.error('Failed to fetch products:', err);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleEdit = (product) => {
    setCurrentProduct(product);
    setIsModalOpen(true);
  };

  const handleDelete = async (product) => {
    try {
      await request(`/a/product/${product.id}`, 'DELETE');
      fetchProducts(); // Refresh the list after deletion
    } catch (err) {
      console.error('Failed to delete product:', err);
    }
  };

  const handleAdd = () => {
    setCurrentProduct(null); // Ensure modal is in "add" state
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentProduct(null);
  };

  const handleSave = async (productData) => {
    try {
      if (currentProduct) {
        // Update operation
        await request(`/a/product/${currentProduct.id}`, 'PUT', { payload: productData });
      } else {
        // Add (create) operation
        await request('/a/product', 'POST', { payload: productData });
      }
      fetchProducts(); // Refresh the list after save
      handleCloseModal(); // Close the modal after operation
    } catch (err) {
      console.error('Failed to save product:', err);
    }
  };

  return (
    <div>
      <AdminTable
        headers={productHeaders}
        data={filteredProducts}
        onEdit={handleEdit}
        onDelete={handleDelete}
        showEditButton
        showDeleteButton
      />
      <AdminModal
        open={isModalOpen}
        onClose={handleCloseModal}
        initialData={currentProduct || {}}
        onSave={handleSave}
        fields={productFields}
      />
      <Fab color="primary" aria-label="add" sx={styles.fab} onClick={handleAdd}>
        <AddIcon />
      </Fab>
    </div>
  );
}

export default ProductManagement;
