import axios from 'axios';
import { store } from '../../redux/configureStore';
import { setLoading, clearLoading } from '../../redux/actions/loading-actions';
import { addAlert } from '../../redux/actions/alert-actions';
import { clearUser } from '../../redux/actions/user-actions';
import websocketService from '../../utils/web-socket-service';

/* eslint-disable import/prefer-default-export */
export const request = (url, method, opts = {}) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    [process.env.REACT_APP_USER_TYPE_HEADER]: 'customer',
  };

  if (opts.isFormData) {
    headers['Content-Type'] = 'multipart/form-data';
  }

  const fetchOptions = {
    method,
    headers,
  };

  if (opts.payload) {
    fetchOptions.data = opts.payload;
  }
  store.dispatch(setLoading());
  return new Promise((resolve, reject) => {
    axios({
      ...fetchOptions,
      ...opts.axiosOptions,
      withCredentials: true,
      url,
      baseURL: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN_NAME}${process.env.REACT_APP_API_URL_PREFIX}${process.env.REACT_APP_API_VERSION}`,
      validateStatus: (status) => status >= 200 && status < 300,
    })
      .then((res) => {
        store.dispatch(clearLoading());
        resolve(res);
      })
      .catch((error) => {
        store.dispatch(clearLoading());
        let errorMessage = 'An error occurred, please try again or contact support.';
        if (error.response && error.response.data && error.response.data.detail && typeof error.response.data.detail === 'string') {
          errorMessage = error.response.data.detail;
          store.dispatch(addAlert({ message: errorMessage, severity: 'error' }));
        }

        if (error.response && error.response.status === 401) {
          console.error('401 error:', error);
          store.dispatch(addAlert({ message: 'Your session has expired, please log in.', severity: 'error' }));
          store.dispatch(clearUser());
          websocketService.disconnect();
          localStorage.removeItem('token');
        }

        reject(error);
      });
  });
};
