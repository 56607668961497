import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import {
  Typography,
  Container,
  useTheme,
} from '@mui/material';
import useStyles from './styles';
import StyledButton from '../../common/styled-components/styled-button';
import { request } from '../../common/utils';

function OrderConfirmation() {
  const { orderIds } = useParams();
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');
  const noOrdersFound = orders.length === 0;
  const theme = useTheme();
  const navigate = useNavigate();
  const styles = useStyles(theme);

  useEffect(() => {
    request(`/get-orders?ids=${orderIds}`, 'GET')
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => {
        console.error('Error fetching orders:', err);
        setError('Failed to fetch order details.');
      });
  }, [orderIds]);

  useEffect(() => {
    ReactGA.event({
      category: 'Order confirmation',
      action: 'order_confirmation',
      label: orderIds,
    });
  }, []);

  if (error) {
    return (
      <Container sx={styles.container}>
        <div>
          <Typography variant="body1">
            Error:
            {error}
          </Typography>
          <Typography variant="body1">Please contact support for assistance.</Typography>
        </div>
      </Container>
    );
  }

  if (noOrdersFound) {
    return (
      <Container sx={styles.container}>
        <Typography variant="body1">No orders found.</Typography>
      </Container>
    );
  }

  const redirectToOrders = () => {
    navigate('/orders');
  };

  return (
    <Container sx={styles.container}>
      <Typography variant="h5" sx={styles.headerText}>Thank you for your order!</Typography>
      <Typography variant="body1" sx={styles.infoTextFirst}>Your order has been sent to the vendor.</Typography>
      <Typography variant="body1" sx={styles.infoTextSecond}>
        The vendor will confirm the order shortly. You will receive an email
        with the pickup address and other details once the order is confirmed.
      </Typography>
      <StyledButton
        variant="contained"
        label="View Orders"
        onClick={redirectToOrders}
      />

    </Container>
  );
}

export default OrderConfirmation;
