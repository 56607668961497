// styles.js or a separate styledComponents.js

import { styled } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Card,
  Rating,
  Typography,
  Grid,
} from '@mui/material';

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(14),
  height: theme.spacing(14),
  marginBottom: theme.spacing(2),
  margin: 'auto', // Center the avatar
}));

export const StyledBoxCentered = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%', // Ensures full width for horizontal centering
});

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
}));

export const StyledRating = styled(Rating)({
  marginTop: '8px',
});

export const StyledTypography = styled(Typography)(({ theme, variant }) => {
  switch (variant) {
    case 'h5':
      return {
        fontWeight: 'bold',
      };
    case 'subtitle1':
      return {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
      };
    case 'body1':
      return {
        marginTop: theme.spacing(1),
        textAlign: 'center',
      };
    default:
      return {};
  }
});

export const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
