// OrderCard.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Box,
  Button,
  IconButton,
  Avatar,
  ListItemAvatar,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import moment from 'moment';
import { request } from '../utils';
import StyledConfirmationComponent from './styled-confirmation-dialog';
import StyledReviewDialog from './styled-review-dialog';
import { formattedAddress } from '../../../utils/formatting-utils';

const useStyles = (theme) => ({
  cardStyles: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
  },
  accordionSummaryStyles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  orderDetailsStyles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    flexGrow: 1,
    maxWidth: '60%',
  },
  dateRange: {
    marginTop: theme.spacing(2),
  },
  secondaryActionButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
    '& .MuiButtonBase-root': {
      paddingX: 0,
      paddingY: 0,
    },
  },
  primaryActionButtons: {
  },
  totalPaid: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: theme.spacing(2),
  },
  actionsContainerStyles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  expandIconStyles: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(2),
    transform: 'translateX(-50%)',
  },
});

function OrderCard({ order: initialOrder, userType }) {
  const [order, setOrder] = useState(initialOrder);
  const [expanded, setExpanded] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [newStatusInfo, setNewStatusInfo] = useState(null);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);

  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenConfirmationDialog = (newStatus) => {
    setNewStatusInfo(newStatus);
    setOpenConfirmationDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const updateOrderStatus = (note) => {
    console.log(note);
    request('/update-order-status', 'POST', {
      payload: {
        order_id: order.id,
        status: newStatusInfo,
        note,
      },
    })
      .then((response) => {
        console.log('Order status updated:', response.data);
        setOrder((prevOrder) => ({ ...prevOrder, order_status: newStatusInfo }));
      })
      .catch((error) => {
        console.error('Error updating order status:', error);
      })
      .finally(() => {
        handleCloseDialog();
      });
  };

  const handleLeaveReview = () => {
    setOpenReviewDialog(true);
  };

  const handleCloseReviewDialog = () => {
    setOpenReviewDialog(false);
  };

  const handleSubmitReview = (star, comment) => {
    request('/review', 'POST', {
      payload: {
        user_id: userType === 'renter' ? order.vendor.id : order.user_id,
        rating: star,
        message: comment,
      },
    })
      .then((response) => {
        console.log('Review submitted:', response.data);
      })
      .catch((error) => {
        console.error('Error updating order status:', error);
      })
      .finally(() => {
        setOpenReviewDialog(false);
      });
  };

  const handleContact = () => {
    const contactId = userType === 'renter' ? order.vendor.id : order.user_id;
    navigate(`/chat/messages/${contactId}`);
  };

  const handleContactSupport = () => {
    navigate('/contact');
  };

  const getConfirmationMessage = () => {
    switch (newStatusInfo) {
      case 'approved':
        return 'Are you sure you want to approve this order?';
      case 'declined':
        return 'Are you sure you want to decline this order?';
      case 'inprogress':
        return 'Are you sure you want to mark this order as picked up?';
      case 'complete':
        return 'Are you sure you want to mark this order as complete?';
      case 'canceled':
        return 'Are you sure you want to cancel this order?';
      default:
        return null;
    }
  };

  const GetCleanOrderStatus = (orderStatus) => {
    if (orderStatus === 'pending_approval') {
      return 'Pending Approval';
    }
    if (orderStatus === 'inprogress') {
      return 'In Progress';
    }
    return orderStatus.charAt(0).toUpperCase() + orderStatus.slice(1);
  };

  const renderOrderInfo = (orderStatus) => (
    <Box>
      <Typography color="textSecondary" sx={styles.dateRange}>
        {moment(order.start_date).format('MMM DD')}
        {' - '}
        {moment(order.end_date).format('MMM DD')}
      </Typography>
      {
        userType === 'renter' && orderStatus === 'approved' ? (
          <Typography color="textSecondary" sx={styles.pickupDetails}>
            Pickup From:
            <br />
            {`${order.vendor.first_name} ${order.vendor.last_name}`}
            <br />
            {formattedAddress(order.vendor.vendor_info.address)}
          </Typography>
        ) : null
      }
      {
        userType === 'vendor' ? (
          <Typography color="textSecondary" sx={styles.pickupDetails}>
            Order From:
            {`${order.user.first_name} ${order.user.last_name}`}
          </Typography>
        ) : null
      }
    </Box>
  );

  const renderCancelOrderButton = (actionType) => (
    <Button
      variant={actionType === 'primary' ? 'outlined' : 'text'}
      onClick={() => handleOpenConfirmationDialog('canceled')}
    >
      Cancel Order
    </Button>
  );

  const renderDeclineOrderButton = (actionType) => (
    <Button
      variant={actionType === 'primary' ? 'outlined' : 'text'}
      onClick={() => handleOpenConfirmationDialog('declined')}
    >
      Decline Order
    </Button>
  );

  const renderApproveOrderButton = (actionType) => (
    <Button
      variant={actionType === 'primary' ? 'outlined' : 'text'}
      onClick={() => handleOpenConfirmationDialog('approved')}
    >
      Approve Order
    </Button>
  );

  const renderMarkOrderPickedUpButton = (actionType) => (
    <Button
      variant={actionType === 'primary' ? 'outlined' : 'text'}
      onClick={() => handleOpenConfirmationDialog('inprogress')}
    >
      Mark As Picked Up
    </Button>
  );

  const renderMarkOrderCompleteButton = (actionType) => (
    <Button
      variant={actionType === 'primary' ? 'outlined' : 'text'}
      onClick={() => handleOpenConfirmationDialog('complete')}
    >
      Mark As Complete
    </Button>
  );

  const renderLeaveReviewButton = (actionType) => (
    <Button
      variant={actionType === 'primary' ? 'outlined' : 'text'}
      onClick={() => handleLeaveReview()}
    >
      Leave Review
    </Button>
  );

  const renderContactButton = (actionType) => (
    <Button
      variant={actionType === 'primary' ? 'outlined' : 'text'}
      onClick={() => handleContact()}
    >
      {`Contact ${userType === 'renter' ? 'Vendor' : 'Renter'}`}
    </Button>
  );

  const renderContactSupportButton = (actionType) => (
    <Button
      variant={actionType === 'primary' ? 'outlined' : 'text'}
      onClick={() => handleContactSupport()}
    >
      Contact Support
    </Button>
  );

  const renderSecondaryActionsForVendor = (orderStatus) => {
    switch (orderStatus) {
      case 'pending_approval':
        return (
          <>
            {renderDeclineOrderButton('secondary')}
            {renderContactButton('secondary')}
          </>
        );
      case 'approved':
        return (
          <>
            {renderContactButton('secondary')}
            {renderCancelOrderButton('secondary')}
            {renderContactSupportButton('secondary')}
          </>
        );
      case 'inprogress':
        return (
          <>
            {renderContactButton('secondary')}
            {renderContactSupportButton('secondary')}
          </>
        );
      case 'complete':
        return (
          <>
            {renderContactSupportButton('secondary')}
          </>
        );
      case 'declined':
      case 'canceled':
        return null;
      default:
        return null;
    }
  };

  const renderPrimaryActionsForVendor = (orderStatus) => {
    switch (orderStatus) {
      case 'pending_approval':
        return renderApproveOrderButton('primary');
      case 'approved':
        return renderMarkOrderPickedUpButton('primary');
      case 'inprogress':
        return renderMarkOrderCompleteButton('primary');
      case 'complete':
        return renderLeaveReviewButton('primary');
      case 'declined':
        return renderContactSupportButton('primary');
      case 'canceled':
        return renderContactSupportButton('primary');
      default:
        return null;
    }
  };

  const renderSecondaryActionsForRenter = (orderStatus) => {
    switch (orderStatus) {
      case 'pending_approval':
        return (
          <>
            {renderCancelOrderButton('secondary')}
          </>
        );
      case 'approved':
        return (
          <>
            {renderCancelOrderButton('secondary')}
          </>
        );
      case 'inprogress':
        return (
          <>
            {renderContactSupportButton('secondary')}
          </>
        );
      case 'complete':
        return (
          <>
            {renderContactSupportButton('secondary')}
          </>
        );
      case 'declined':
      case 'canceled':
        return null;
      default:
        return null;
    }
  };

  const renderPrimaryActionsForRenter = (orderStatus) => {
    switch (orderStatus) {
      case 'pending_approval':
      case 'approved':
      case 'inprogress':
        return renderContactButton('primary');
      case 'complete':
        return renderLeaveReviewButton('primary');
      case 'declined':
      case 'canceled':
        return renderContactSupportButton('primary');
      default:
        return null;
    }
  };

  return (
    <Card sx={styles.cardStyles}>
      <Accordion expanded={expanded} onChange={handleExpandClick}>
        <AccordionSummary sx={styles.accordionSummaryStyles}>
          <Box flexGrow={1} sx={styles.orderDetailsStyles}>
            <Typography variant="h6" component="h2">
              {`Order number: ${order.id}`}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {GetCleanOrderStatus(order.order_status)}
            </Typography>
            {
              renderOrderInfo(order.order_status)
            }

            <Box sx={styles.secondaryActionButtons}>
              {userType === 'renter' ? renderSecondaryActionsForRenter(order.order_status) : renderSecondaryActionsForVendor(order.order_status)}
            </Box>
          </Box>
          <Box sx={styles.actionsContainerStyles}>
            {userType === 'renter' ? renderPrimaryActionsForRenter(order.order_status) : renderPrimaryActionsForVendor(order.order_status)}
          </Box>
          <IconButton sx={styles.expandIconStyles} onClick={handleExpandClick}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {order.order_items.map((item) => (
              <ListItem key={item.id}>
                <ListItemAvatar>
                  <Avatar src={item.product.images[0].image} alt={item.product.name} />
                </ListItemAvatar>
                <Typography variant="body1">
                  {item.product.name}
                  {item.quantity > 1 ? ` x ${item.quantity}` : ''}
                </Typography>
              </ListItem>
            ))}
          </List>
          <Box sx={styles.totalPaid}>
            <Typography>
              Total Paid: $
              {order.total_payable}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>

      <StyledConfirmationComponent
        open={openConfirmationDialog}
        onClose={handleCloseDialog}
        title="Confirm Action"
        onConfirm={updateOrderStatus}
        message={getConfirmationMessage()}
        showNote={newStatusInfo === 'declined' || newStatusInfo === 'canceled'}
        noteRequired={newStatusInfo === 'declined' || newStatusInfo === 'canceled'}
      />

      <StyledReviewDialog
        open={openReviewDialog}
        onClose={handleCloseReviewDialog}
        onConfirm={handleSubmitReview}
        userToReview={userType === 'vendor' ? order.user : order.vendor}
      />
    </Card>
  );
}

OrderCard.propTypes = {
  order: PropTypes.oneOfType([PropTypes.object]).isRequired,
  userType: PropTypes.oneOf(['renter', 'vendor']).isRequired,
};

export default OrderCard;
