import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/configureStore';
import './index.css';
import App from './App';

const { PUBLIC_URL } = process.env;

const theme = createTheme({
  palette: {
    primary: {
      main: '#3586EF',
      light: '#4ba4ff',
      lightest: '#c2dbfa',
    },
    secondary: {
      main: '#FA6800',
      light: '#fcc423',
    },
    background: {
      default: '#185478',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Open Sans", sans-serif',
    button: {
      textTransform: 'none',
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.4rem',
    },
    h4: {
      fontSize: '1.8rem',
    },
    h3: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '2.6rem',
    },
  },
  spacing: 8,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // disabled ripple effect in whole app
      },
    },
    MuiTabs: {
      defaultProps: {
        variant: 'scrollable', // tabs should be scrollable by default
        scrollButtons: 'auto',
      },
    },
  },
});

// TODO: Uncomment when going to production
// Unomment the following to enable sentry

Sentry.init({
  dsn: 'https://4c3bac41d7cf6676cf02b2a3cf03da0b@o4506685346938880.ingest.sentry.io/4506685850714112',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={PUBLIC_URL}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);
