import React from 'react';
import PropTypes from 'prop-types';
import SummaryCard from './summary-card';

function ProductsSummary({ productCount }) {
  return (
    <SummaryCard
      title="Total Products"
      value={productCount}
      link="/a/product-management"
    />
  );
}

// Add prop validation
ProductsSummary.propTypes = {
  productCount: PropTypes.number.isRequired,
};

export default ProductsSummary;
