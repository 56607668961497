import {
  SET_USER,
  CLEAR_USER,
} from '../actions/types';

const initialState = {
  userDetails: {},
  timeLoggedIn: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        userDetails: action.payload,
        timeLoggedIn: Date.now(),
      };
    case CLEAR_USER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default userReducer;
