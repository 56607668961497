import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import NotFoundImage from '../../../resource/images/no-image.webp';

function StyledNotFoundComponent({
  content,
  showButton,
  buttonText,
  buttonOnClick,
}) {
  // Styles
  const sxStyles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
      p: 2, // shorthand for padding
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
    },
    title: {
      mt: 2,
      color: 'text.secondary',
    },
    description: {
      color: 'text.secondary',
      mb: 2,
    },
    button: {
      mt: 2,
    },
  };

  return (
    <Box sx={sxStyles.container}>
      <img src={NotFoundImage} alt="Category Not Found" style={sxStyles.image} />
      <Typography variant="h5" sx={sxStyles.title}>
        {content.title}
      </Typography>
      <Typography sx={sxStyles.description}>
        {content.message}
      </Typography>
      {
        showButton && (
          <Button
            variant="contained"
            sx={sxStyles.button}
            onClick={buttonOnClick}
          >
            {buttonText}
          </Button>
        )
      }
    </Box>
  );
}

StyledNotFoundComponent.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
};

StyledNotFoundComponent.defaultProps = {
  showButton: true,
  buttonText: '',
  buttonOnClick: null,
};

export default StyledNotFoundComponent;
