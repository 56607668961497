import React, { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LOGIN_EXPIRY_TIME_SECONDS from './components/common/constants';
import { clearUser } from './redux/actions/user-actions';

import Routes from './route/routes';

const TRACKING_ID = 'G-PBR7VR1Z97'; // your Measurement ID

function App({ user, timeLoggedIn, clearUserDetails }) {
  // TODO: Can we auto logout the user after the time has expired?
  // This will check if the user is logged in
  // and if the time has expired when the page is loaded
  useEffect(() => {
    if (!user || Object.keys(user).length === 0) {
      localStorage.removeItem('token');
      clearUserDetails();
    } else if (timeLoggedIn) {
      const elapsedTime = Date.now() - timeLoggedIn;
      if (elapsedTime >= LOGIN_EXPIRY_TIME_SECONDS * 1000) {
        localStorage.removeItem('token');
        clearUserDetails();
      }
    }
  }, []);

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GoogleOAuthProvider clientId="384858041798-vabcn71h22fchf3043ogq60t7fb8ehso.apps.googleusercontent.com">
        <Routes user={user} />
      </GoogleOAuthProvider>
    </LocalizationProvider>
  );
}

App.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  timeLoggedIn: PropTypes.number,
  clearUserDetails: PropTypes.func.isRequired,
};

App.defaultProps = {
  timeLoggedIn: null,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
  timeLoggedIn: state.user.timeLoggedIn,
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      clearUserDetails: clearUser,
    },
  ),
);
export default enhance(App);
