export const formattedDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return 'Anytime';
  }
  const formattedDate = `${new Date(startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', timeZone: 'UTC' })} - ${new Date(endDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', timeZone: 'UTC' })}`;
  // console.log(formattedDate);
  return formattedDate;
};

export const formattedAddress = (address) => {
  if (!address) {
    return '';
  }
  return `${address.address_line1}, ${address.city}, ${address.province} ${address.postal_code.replace(' ', '')}`;
};
