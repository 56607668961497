/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

function StyledButton(props) {
  const {
    variant,
    label,
    sx,
    ...restProps
  } = props;

  const customSxContained = (theme) => ({
    paddingX: theme.spacing(4),
    borderRadius: theme.spacing(2),
  });

  const customSxText = (theme) => ({
    paddingX: theme.spacing(4),
    borderRadius: theme.spacing(4),
  });

  const theme = useTheme();
  const customSx = variant === 'contained' ? customSxContained(theme) : customSxText(theme);
  const mergedSx = { ...customSx, ...sx };

  return (
    <Button
      variant={variant}
      sx={mergedSx}
      {...restProps}
    >
      {label}
    </Button>
  );
}

StyledButton.propTypes = {
  variant: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sx: PropTypes.oneOfType([PropTypes.object]),
};

StyledButton.defaultProps = {
  sx: {},
};

export default StyledButton;
