import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  useTheme,
} from '@mui/material';
import OrderCard from '../../common/styled-components/styled-order-card';
import { request } from '../../common/utils';
import StyledNotFoundComponent from '../../common/styled-components/styled-not-found-component'; // Adjust the import path as needed

const useStyles = (theme) => ({
  topContainer: {
    marginX: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginX: 'auto',
    },
  },
});

function OrderList() {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');

  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();

  useEffect(() => {
    const statusesCSV = 'pending_approval,approved,declined,inprogress,complete,canceled';
    const fetchOrders = async () => {
      try {
        const response = await request(`/get-user-orders?statuses=${statusesCSV}`, 'GET');
        setOrders(response.data); // Assuming the response has a data property
      } catch (err) {
        setError('Failed to fetch orders.');
      }
    };

    fetchOrders();
  }, []);

  const redirectToHome = () => {
    navigate('/');
  };

  if (error) {
    return (
      <Card>
        <CardContent>
          <Typography color="error" gutterBottom>
            Error:
            {error}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box sx={styles.topContainer}>
      {orders.length > 0 ? (
        orders.map((order) => <OrderCard key={order.id} order={order} userType="renter" />)
      ) : (
        <StyledNotFoundComponent
          content={{
            title: 'No orders yet',
            message: "Looks like you haven't placed any orders yet.",
          }}
          buttonText="Start Your Adventure Now"
          buttonOnClick={redirectToHome}
        />
      )}
    </Box>
  );
}

export default OrderList;
