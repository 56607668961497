import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { request } from '../common/utils';
import NotificationList from './notification-list';

function NotificationsPage() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    request('/get-push-notifications', 'GET')
      .then((res) => {
        console.log('Update notifications:', res.data);
        setNotifications(res.data); // Assuming 'data' contains the notifications
      })
      .catch((err) => {
        console.error('Error fetching notifications:', err);
      });
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>All Notifications</Typography>
      <NotificationList notifications={notifications} setNotifications={setNotifications} />
    </Container>
  );
}

export default NotificationsPage;
