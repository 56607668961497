import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Snackbar, useTheme,
} from '@mui/material';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { request } from '../common/utils';
import { setUser } from '../../redux/actions/user-actions';
import useStyles from './styles';
import ChangePasswordModal from './change-password-modal';
import UploadableAvatar from '../common/uploadable-avatar';
import StyledTextField from '../common/styled-components/styled-textfield';
import StyledButton from '../common/styled-components/styled-button';
import LocationField from '../common/styled-components/styled-autocomplete-location';
import { getPostalCode, formatAddress } from '../../utils/geolocation';

function SelfProfile({ user, setUserDetails }) {
  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [phone, setPhone] = useState(user.phone);
  const [photo, setPhoto] = useState(user.profile_picture?.image);

  const [address] = useState(formatAddress(user.vendor_info?.address));
  const [place, setPlace] = useState(null);
  const [aboutMe, setAboutMe] = useState(user.vendor_info?.about_me);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const theme = useTheme();
  const styles = useStyles(theme);

  const handlePhotoChange = (photoFile) => {
    request('/upload-user-image/', 'POST', {
      isFormData: true,
      axiosOptions: { params: { user_id: user.id } },
      payload: { file: photoFile },
    })
      .then((res) => {
        setPhoto(res.data.s3_url);
      })
      .catch((err) => console.log(err));
  };

  const handleProfileUpdate = () => {
    let addressData = null;
    if (place) {
      const formattedAddress = place.formatted_address;
      const formattedAddressList = formattedAddress.split(',');
      addressData = {
        address_line1: formattedAddressList[0].trim(),
        address_line2: '',
        city: formattedAddressList[1].trim(),
        province: formattedAddressList[2].trim().split(' ')[0].trim(),
        postal_code: getPostalCode(place),
        country: formattedAddressList[3].trim(),
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        google_places_id: place.place_id,
      };
    }

    const updatedProfile = {
      email,
      first_name: firstName,
      last_name: lastName,
      phone,
      profile_picture_url: photo,
      about_me: aboutMe,
      address: addressData,
    };

    request('/update-profile/', 'PUT', {
      payload: { ...updatedProfile },
    })
      .then((res) => {
        if (res.status === 200) {
          setUserDetails(res.data);
          setAlertMessage('Successfully Updated Profile');
          setAlertOpen(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleOpenChangePasswordModal = () => setIsChangePasswordModalOpen(true);
  const handleCloseChangePasswordModal = () => setIsChangePasswordModalOpen(false);

  const handlePasswordChangeSuccess = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handlePasswordChangeError = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setAlertMessage('');
  };

  return (
    <Box sx={styles.topContainer}>
      <UploadableAvatar
        photoUrl={photo}
        onPhotoChange={handlePhotoChange}
        style={styles.photoUpload}
      />
      <StyledTextField
        label="Email"
        fullWidth
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />

      <Box sx={styles.passwordButtonContainer}>
        <StyledButton
          variant="contained"
          onClick={handleOpenChangePasswordModal}
          label="Change Password"
          sx={styles.passwordButton}
        />
      </Box>

      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={handleCloseChangePasswordModal}
        onSuccess={handlePasswordChangeSuccess}
        onError={handlePasswordChangeError}
      />

      <StyledTextField
        label="First Name"
        fullWidth
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        required
      />
      <StyledTextField
        label="Last Name"
        fullWidth
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        required
      />

      <StyledTextField
        label="Phone Number"
        fullWidth
        type="tel"
        value={phone || ''}
        onChange={(e) => setPhone(e.target.value)}
      />

      {
        user.role === 'vendor' && user.vendor_info ? (
          <Box>
            <LocationField
              formattedAddress={address}
              onSelectedPlace={(selectedPlace) => setPlace(selectedPlace)}
              required
            />
            <StyledTextField
              multiline
              label="About Me"
              placeholder="About Me"
              fullWidth
              value={aboutMe}
              onChange={(e) => setAboutMe(e.target.value)}
            />
          </Box>
        ) : null
      }

      <StyledButton
        variant="contained"
        label="Update Profile"
        onClick={handleProfileUpdate}
        disabled={!(email && firstName && lastName)}
      >
        Update Profile
      </StyledButton>

      <Snackbar
        open={alertOpen}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        message={alertMessage}
      />
    </Box>
  );
}

SelfProfile.propTypes = {
  setUserDetails: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]),
};

SelfProfile.defaultProps = {
  user: {},
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      setUserDetails: setUser,
    },
  ),
);

export default enhance(SelfProfile);
