import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Grid,
  Button,
  Box,
  Divider,
  FormControlLabel,
  Checkbox,
  Link,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { request } from '../../common/utils';
import useStyles from './styles';
import StyledConfirmationComponent from '../../common/styled-components/styled-confirmation-dialog';
import StyledNotFoundComponent from '../../common/styled-components/styled-not-found-component';
import OrderCoupon from '../order-coupon/order-coupon';
import { store } from '../../../redux/configureStore';
import { addAlert } from '../../../redux/actions/alert-actions';

import SquarePaymentComponent from '../payment/payment';

function Cart() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const statusesCSV = 'incart';

  const [orders, setOrders] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({ orderId: null, itemId: null });
  const [showPaymentComponent, setShowPaymentComponent] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [depositAgreed, setDepositAgreed] = useState(false);
  const [couponInfo, setCouponInfo] = useState({ applied: false, discountAmount: 0, code: '' });

  const navigate = useNavigate();

  const loadPage = () => {
    request(`/get-user-orders?statuses=${statusesCSV}`, 'GET')
      .then((res) => {
        console.log(res);
        setOrders(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    loadPage();
  }, []);

  const noItemsAvailable = orders && orders.every((order) => order.order_items.length === 0);
  const calculateTotal = (field) => {
    if (orders.length === 0) {
      return 0;
    }
    return orders.reduce((acc, order) => acc + order[field], 0);
  };
  const renderTotalItem = (label, value) => (
    <Box sx={styles.totalItem}>
      <Typography sx={styles.totalLabel}>
        {label}
        :
      </Typography>
      <Typography sx={styles.totalValue}>{`$${value.toFixed(2)}`}</Typography>
    </Box>
  );

  const handleOpenDialog = (orderId, itemId) => {
    setDeleteInfo({ orderId, itemId });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const redirectToHome = () => {
    navigate('/');
  };

  const handleConfirmDelete = () => {
    const { orderId, itemId } = deleteInfo;
    request('/remove_item_from_order', 'POST', {
      payload: {
        order_id: orderId,
        order_item_id: itemId,
      },
    })
      .then((updatedOrder) => {
        // Update the state with the new order data or remove the order if it's empty
        setOrders(orders.map((order) => {
          if (order.id === orderId) {
          // Check if the order still has items after deletion
            if (updatedOrder.data.order_items && updatedOrder.data.order_items.length > 0) {
              return updatedOrder.data; // Return the updated order
            }
            return null; // Return null to filter this order out
          }
          return order; // Return the order unchanged if it's not the one being updated
        }).filter((order) => order != null)); // Remove orders that are now null
        store.dispatch(addAlert({ message: `${updatedOrder.data.order_items.product.name} has been removed from your cart.`, severity: 'info' }));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        handleCloseDialog();
      });
  };

  const handleCouponApplied = (couponData) => {
    setCouponInfo({
      applied: true,
      discountAmount: couponData.discountAmount,
      code: couponData.code,
    });
    loadPage();
  };

  const handleCouponError = (error) => {
    console.log('Error applying coupon:', error);
  };

  return (
    <div style={styles.page}>
      {orders.length === 0 ? (
        // Render the NotFoundComponent with full width when there are no orders
        <StyledNotFoundComponent
          content={{
            title: 'No items in cart',
            message: "Looks like you don't have any items in your cart yet.",
          }}
          buttonText="Start Your Adventure Now"
          buttonOnClick={redirectToHome}
        />
      ) : (
        // Render the Grid only when there are orders
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={styles.orderDetails}>
            {orders.length === 0 || noItemsAvailable ? (
              <StyledNotFoundComponent />
            ) : (
              orders.map((order) => (
                <Card key={order.id} sx={{ margin: 2 }}>
                  <CardContent>
                    <Box sx={styles.header}>
                      <Typography variant="h6">
                        {`Renting From: ${order.vendor.first_name} ${order.vendor.last_name}`}
                      </Typography>
                      <Typography color="textSecondary" sx={styles.dateRange}>
                        {moment(order.start_date).format('MMM DD')}
                        {' - '}
                        {moment(order.end_date).format('MMM DD')}
                      </Typography>
                    </Box>
                    <List>
                      {order.order_items.map((item, index) => (
                        <ListItem key={item.id} sx={styles.orderLineItem}>
                          <Typography sx={styles.numebr}>
                            {`${index + 1}.`}
                          </Typography>
                          <ListItemAvatar>
                            <Avatar
                              sx={styles.productImage}
                              src={item.product.images[0].image}
                              alt={item.product.name}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${item.product.name}${item.quantity !== 1 ? ` x ${item.quantity}` : ''}`}
                            secondary={(
                              <>
                                <Typography>
                                  {`$${item.unit_price}/day`}
                                </Typography>
                                <Typography>
                                  {`$${order.total_deposit} Value`}
                                </Typography>
                              </>
                            )}
                          />
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleOpenDialog(order.id, item.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItem>
                      ))}
                    </List>
                    <Box sx={styles.orderPrices}>
                      <Divider variant="inset" sx={styles.line} />
                      <Typography variant="subtitle1">
                        {`Total Rental Price: ${order.total_price.toFixed(2)}`}
                      </Typography>
                      <Typography variant="subtitle1">
                        {`Total Payable: ${order.total_payable.toFixed(2)}`}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ))
            )}
          </Grid>

          <Grid item xs={12} md={5} sx={styles.orderTotals}>
            {orders.length > 0 && (
            <Box sx={styles.totalItemsContainer}>
              {renderTotalItem('Total Rental Price', calculateTotal('total_price'))}
              {renderTotalItem('Total Tax', calculateTotal('total_tax'))}
              {renderTotalItem('Total Discount', calculateTotal('total_discount'))}
              {renderTotalItem('Total Payable', calculateTotal('total_payable'))}

              {/* Wrapper for the coupon */}
              <Box sx={styles.orderCoupon}>
                <OrderCoupon
                  onCouponApplied={handleCouponApplied}
                  onCouponError={handleCouponError}
                />
                {couponInfo.applied && (
                  <Typography>
                    Coupon &quot;
                    <Typography sx={styles.couponApplied} variant="soft">{couponInfo.code}</Typography>
                    &quot; applied
                  </Typography>
                )}
                {/* Rest of the order totals rendering */}
              </Box>
              {/* Wrapper for the checkout button and payment component */}
              <Box sx={styles.checkoutButtonWrapper}>
                <FormControlLabel
                  sx={styles.disclaimerTC}
                  control={(
                    <Checkbox
                      checked={termsAgreed}
                      onChange={(e) => setTermsAgreed(e.target.checked)}
                      sx={{ paddingTop: '0px' }}
                    />
                  )}
                  label={(
                    <span>
                      I agree to the
                      {' '}
                      <Link href="/terms-and-conditions">Terms and Conditions</Link>
                    </span>
                  )}
                />
                <FormControlLabel
                  sx={styles.disclaimerDeposit}
                  control={(
                    <Checkbox
                      checked={depositAgreed}
                      onChange={(e) => setDepositAgreed(e.target.checked)}
                      sx={{ paddingTop: '0px' }}
                    />
                  )}
                  label={`I understand that I am renting product(s) worth $${calculateTotal('total_deposit')} and will be liable for any damages or failure to return.`}
                />
                {!showPaymentComponent && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={styles.checkoutButton}
                  onClick={() => setShowPaymentComponent(true)}
                  disabled={!termsAgreed || !depositAgreed}
                >
                  Checkout Now
                </Button>
                )}

                {/* Conditional rendering of Payment Component */}
                {
                  showPaymentComponent && (
                    <SquarePaymentComponent
                      style={styles.paymentComponent}
                      totalAmount={calculateTotal('total_payable')}
                    />
                  )
                }
              </Box>
            </Box>
            )}
          </Grid>

          {/* Confirmation Dialog */}
          <StyledConfirmationComponent
            open={openDialog}
            onClose={handleCloseDialog}
            title="Are you sure you want to delete this from your cart?"
            onConfirm={handleConfirmDelete}
          >
            Are you sure you want to delete this from your cart?
          </StyledConfirmationComponent>
        </Grid>
      )}

    </div>
  );
}

export default Cart;
