import React, { useState } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Link,
} from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailIcon from '@mui/icons-material/Mail';
import { request } from '../common/utils';
import styles from './styles';
import { store } from '../../redux/configureStore';
import { addAlert } from '../../redux/actions/alert-actions';

function Contact() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    request('/send-contact-email', 'POST', {
      payload: {
        name: formData.fullName,
        email: formData.email,
        subject: formData.subject,
        message: formData.message,
      },
    })
      .then((res) => {
        console.log(res);
        store.dispatch(addAlert({ message: 'Your message has been sent', severity: 'info' }));
      })
      .catch((err) => {
        console.error(err);
        store.dispatch(addAlert({ message: 'Failed to send message. Please send an email to info@rentovault.com', severity: 'error' }));
      });
  };

  return (
    <>
      <Typography component="h2" sx={styles.title}>Send Message</Typography>
      <Box sx={styles.contactContainer}>
        <Box sx={styles.mailBoxContainer}>
          <Box sx={styles.fullNameAndEmailContainer}>
            <TextField
              placeholder="Full Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              sx={styles.textFieldFullName}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              sx={styles.textFieldFullName}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={styles.subjectContainer}>
            <TextField
              placeholder="Subject"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              sx={styles.textFieldFullName}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TitleOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={styles.subjectContainer}>
            <TextField
              placeholder="Your Message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              sx={styles.textFieldFullName}
              multiline
              minRows={4}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={styles.multilinedAdorn}>
                    <ChatOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={styles.sendButtonContainer}>
            <Button
              variant="contained"
              sx={styles.sendMessageButton}
              onClick={handleSubmit}
            >
              Send Message
            </Button>
          </Box>
        </Box>
        <Box sx={styles.socialIconContainer}>
          <Typography component="h2" sx={styles.contactUs}>Contact us</Typography>
          <Link
            href="https://www.facebook.com/profile.php?id=100081840524520"
            underline="none"
            target="_blank"
            sx={styles.socialLinks}
          >
            <FacebookIcon />
            Facebook
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=100081840524520"
            underline="none"
            target="_blank"
            sx={styles.socialLinks}
          >
            <InstagramIcon />
            Instagram
          </Link>
          <Link
            href="mailto:info@rentovault.com"
            underline="none"
            target="_blank"
            sx={styles.socialLinks}
          >
            <MailIcon />
            info@rentovault.com
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default Contact;
