import commonStyles from '../common/styles';

const useStyles = (theme) => ({
  ...commonStyles,
  searchBarContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  productListContainer: {
    paddingX: 2,
    paddingY: 4,
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: '24px',
  },
  productItem: {
    padding: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    boxShadow: '2px 2px 5px rgba(100, 100, 100, .25)',
    '&:hover': {
      boxShadow: '5px 5px 10px rgba(100, 100, 100, .25)',
    },
    [theme.breakpoints.up('md')]: {
      width: '280px',
    },
    // '@media (min-width: 540px)': {
    //   width: '45%',
    // },
    // '@media (min-width: 810px)': {
    //   width: '30%',
    // },
    // '@media (min-width: 1280px)': {
    //   width: '22.5%',
    // },
    // '@media (min-width: 1600px)': {
    //   width: '18%',
    // },
    // '@media (min-width: 1920px)': {
    //   width: '15%',
    // },
  },
  productImageConatiner: {
    borderRadius: '10px',
    width: '100%',
    height: '60%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pading: 1,
    overflow: 'hidden',
  },
  productMainImage: {
    width: '80%',
    height: '80%',
    objectFit: 'cover',
    transition: 'transform .5s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  vendorSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  vendorInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
    cursor: 'pointer',
  },
  nameAndRating: {
    display: 'flex',
    flexDirection: 'column',
  },
  vendorName: {
    fontSize: '10px',
  },
  noReviews: {
    fontSize: '8px',
  },
  searchButton: {
    borderRadius: '20px',
    paddingX: 1,
  },
  productInfoSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  productTitle: {
    fontSize: '16px',
    fontWeight: '1000',
  },
  productPrice: {
    fontSize: '12px',
  },
  distance: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginRight: '16px',
  },
  description: {
    fontSize: '10px',
    // height: '105px',
    // width: '280px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '7',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pagination: {
    margin: 1,
    display: 'none',
  },
});

export default useStyles;
