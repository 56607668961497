import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import DOMPurify from 'dompurify';
import {
  Avatar,
  Box,
  Rating,
  TablePagination,
  Typography,
  useTheme,
} from '@mui/material';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import SearchBar from '../common/search-bar';
import { request } from '../common/utils';
import StyledNotFoundComponent from '../common/styled-components/styled-not-found-component';
import StyledButton from '../common/styled-components/styled-button';
import useStyles from './styles';

function productList(props) {
  const {
    searchFilter,
  } = props;

  const [expandSearch, setExpandSearch] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();
  const theme = useTheme();
  const styles = useStyles(theme);

  const redirectToProductDetails = (id) => {
    navigate(`/product?id=${id}&start=${searchFilter.startDate}&end=${searchFilter.endDate}`);
  };

  const onRowsPerPageChange = (e) => {
    const currentRowsPerPage = parseInt(e.target.value, 10);
    setCurrentPage(0);
    setRowsPerPage(currentRowsPerPage);
  };

  const onPageChange = (e, newPage) => {
    e.preventDefault();
    const currentNewPage = parseInt(newPage, 10);
    setCurrentPage(currentNewPage);
  };

  const redirectToVendorProfile = (userId) => {
    navigate(`/vendor-profile/${userId}`);
  };

  const getLatLongString = (place) => {
    // For some reason the lat and long are functions in some cases and values in others
    if (typeof place.geometry.location.lat === 'function') {
      return `${place.geometry.location.lat()}:${place.geometry.location.lng()}`;
    }
    return `${place.geometry.location.lat}:${place.geometry.location.lng}`;
  };

  const fetchProductList = () => {
    let url = '';
    if (searchFilter.startDate && searchFilter.endDate) {
      url = `/products?search_text=${searchFilter.searchText}&start_date=${searchFilter.startDate}&end_date=${searchFilter.endDate}&location=${getLatLongString(searchFilter.place)}&skip=${currentPage * rowsPerPage}&limit=${rowsPerPage}`;
    } else {
      url = `/products?search_text=${searchFilter.searchText}&location=${getLatLongString(searchFilter.place)}&skip=${currentPage * rowsPerPage}&limit=${rowsPerPage}`;
    }

    return request(
      url,
      'GET',
    )
      .then((res) => {
        setProducts(res.data);
        setTotalCount(200);
      })
      .catch((err) => {
        console.error('Error fetching products:', err);
      });
  };

  useEffect(() => {
    fetchProductList();
  }, [currentPage]);

  useEffect(() => {
    ReactGA.event({
      category: 'Product search',
      action: 'search',
      label: searchFilter.searchText,
    });
  }, []);

  const expandSearchBar = () => {
    setExpandSearch(true);
  };

  const handleSearch = () => {
    setExpandSearch(false);
    fetchProductList();
  };

  return (
    <>
      <Box sx={styles.searchBarContainer}>
        <SearchBar
          handleClickOnSearch={handleSearch}
          isCollapsed={!expandSearch}
          handleClickOnCollapsed={expandSearchBar}
        />
      </Box>
      {
        products.length === 0
          ? (
            <StyledNotFoundComponent
              content={{
                title: 'No products',
                message: 'It looks like there isn\'t any products you are looking for.',
              }}
              showButton={false}
            />
          )
          : (
            <>
              <Box sx={styles.productListContainer}>
                {
                  products.map((product) => (
                    <Box sx={styles.productItem}>
                      <Box
                        sx={styles.productImageConatiner}
                        onClick={() => redirectToProductDetails(product.id)}
                      >
                        <Box component="img" src={product.images[0].image} sx={styles.productMainImage} />
                      </Box>
                      <Box sx={styles.vendorSection}>
                        <Box
                          sx={styles.vendorInfo}
                          onClick={() => redirectToVendorProfile(product.owner_id)}
                        >
                          <Avatar
                            src={product.owner_profile_picture
                              ? product.owner_profile_picture.image
                              : null}
                          >
                            {
                              product.owner_first_name.charAt(0)
                            }
                          </Avatar>
                          <Box sx={styles.nameAndRating}>
                            <Typography component="span" sx={styles.vendorName}>
                              {
                                `${product.owner_first_name} ${product.owner_last_name}`
                              }
                            </Typography>
                            {
                              product.owner_rating ? (
                                <Rating
                                  value={product.owner_rating}
                                  precision={0.5}
                                  sx={styles.vendorName}
                                  readOnly
                                />
                              ) : (
                                <Typography color="textSecondary" sx={styles.noReviews}>
                                  No reviews yet
                                </Typography>
                              )
                            }
                          </Box>
                        </Box>
                        <StyledButton
                          variant="contained"
                          label="View Details"
                          onClick={() => redirectToProductDetails(product.id)}
                        />
                      </Box>
                      <Box
                        sx={styles.productInfoSection}
                        onClick={() => redirectToProductDetails(product.id)}
                      >
                        <Box sx={styles.nameAndRating}>
                          <Typography component="span" sx={styles.productTitle}>
                            {product.name}
                          </Typography>
                          <Typography component="span" sx={styles.productPrice}>
                            {`$${product.price} per day`}
                          </Typography>
                        </Box>
                        <Typography component="span" sx={styles.distance}>
                          {`${product.distance.toPrecision(2)} km from you`}
                        </Typography>
                      </Box>
                      <Typography
                        component="p"
                        sx={styles.description}
                        onClick={() => redirectToProductDetails(product.id)}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(product.description),
                        }}
                      />
                    </Box>
                  ))
                }
              </Box>
              <TablePagination
                component="div"
                showFirstButton
                showLastButton
                count={totalCount}
                page={currentPage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 20, 50, 100]}
                onRowsPerPageChange={onRowsPerPageChange}
                onPageChange={onPageChange}
                labelRowsPerPage="rows"
                sx={styles.pagination}
              />
            </>
          )
      }
    </>
  );
}

productList.propTypes = {
  searchFilter: PropTypes.oneOfType([PropTypes.object]),
};

productList.defaultProps = {
  searchFilter: {},
};

const mapStateToProps = (state) => ({
  searchFilter: state.product.searchFilter,
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
    },
  ),
);

export default enhance(productList);
