const useStyles = (theme) => ({
  container: {
    margin: 'auto',
    textAlign: 'center',
  },
  headerText: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  infoTextFirst: {
    marginBottom: theme.spacing(1),
    paddingX: theme.spacing(4),
  },
  infoTextSecond: {
    marginBottom: theme.spacing(4),
    paddingX: theme.spacing(4),
  },
  orderContainer: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    gap: '10px',
  },
  link: {
    textDecoration: 'none',
  },
});

export default useStyles;
