import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import CouponsSummary from './coupon-summary';
import OrdersSummary from './order-summary';
import UsersSummary from './user-summary';
import { request } from '../../common/utils'; // Assuming you have a utility for API requests
import ProductsSummary from './product-summary';

function AdminDashboard() {
  const [couponCount, setCouponCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [vendorCount, setVendorCount] = useState(0);
  const [productCount, setProductCount] = useState(0);

  useEffect(() => {
    request('/a/summary', 'GET')
      .then((res) => {
        setCouponCount(res.data.coupon_count);
        setOrderCount(res.data.order_count);
        setUserCount(res.data.user_count);
        setVendorCount(res.data.vendor_count);
        setProductCount(res.data.product_count);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <CouponsSummary couponCount={couponCount} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <OrdersSummary orderCount={orderCount} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <UsersSummary userCount={userCount} vendorCount={vendorCount} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ProductsSummary productCount={productCount} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminDashboard;
