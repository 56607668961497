import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, Box } from '@mui/material';
import { request } from '../../common/utils';

function OrderCoupon({ onCouponApplied, onCouponError }) {
  const [couponCode, setCouponCode] = useState('');

  const handleApplyCoupon = () => {
    console.log('Applying coupon:', couponCode);
    request(`/apply-coupon/${couponCode}`, 'POST')
      .then((response) => {
        console.log('Coupon response:', response);
        onCouponApplied(response.data);
      })
      .catch((error) => {
        onCouponError(error.response.data);
        console.error('Error applying coupon:', error);
      });
  };

  return (
    <>
      <Box display="flex" gap={2}>
        <TextField
          label="Coupon Code"
          variant="outlined"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={handleApplyCoupon}>
          Apply
        </Button>
      </Box>
    </>
  );
}

OrderCoupon.propTypes = {
  onCouponApplied: PropTypes.func.isRequired,
  onCouponError: PropTypes.func.isRequired,
};

export default OrderCoupon;
