import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme,
} from '@mui/material';
import StyledTextField from '../common/styled-components/styled-textfield';
import { request } from '../common/utils'; // Adjust the import path as needed

const useStyles = () => ({
  currentPassword: {
    marginTop: 4,
  },
});

function ChangePasswordModal({
  isOpen, onClose, onSuccess, onError,
}) {
  const theme = useTheme();
  const styles = useStyles(theme);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const isConfirmPasswordMatch = () => newPassword === confirmNewPassword;

  const handleChangePassword = () => {
    if (newPassword !== confirmNewPassword) {
      onError('New password and confirm password do not match.');
      return;
    }

    request('/change-password/', 'POST', {
      payload: {
        current_password: currentPassword,
        new_password: newPassword,
      },
    })
      .then(() => {
        onSuccess('Password successfully changed.');
        onClose();
      })
      .catch(() => {
        onError('Failed to change password. Please try again.');
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <StyledTextField
          label="Current Password"
          type="password"
          fullWidth
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
          sx={styles.currentPassword}
        />
        <StyledTextField
          label="New Password"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <StyledTextField
          error={confirmNewPassword && !isConfirmPasswordMatch()}
          label="Confirm New Password"
          type="password"
          fullWidth
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleChangePassword}
          disabled={!(currentPassword && newPassword && isConfirmPasswordMatch())}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
