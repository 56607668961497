const styles = {
  container: {
    flexGrow: 1,
    padding: 2,
    gap: 2,
  },
  header: {
    marginBottom: 2,
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'stretch',
  },
  gridItem: {
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 3,
  },
  squareImage: {
    width: '80%',
    height: '80%',
    objectFit: 'cover',
    paddingX: 2,
  },
  cardContent: {
  },
  productName: {
    marginBottom: 0,
  },
  productDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '4',
    WebkitBoxOrient: 'vertical',
  },
  fab: {
    right: '40px',
    bottom: '50px',
    position: 'fixed',
  },
};

export default styles;
