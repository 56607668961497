import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Card, CardContent, Button, Typography, Box, Divider,
} from '@mui/material';
import { decrementUnreadNotificationCounter } from '../../redux/actions/unread-notification-counter-action';
import { request } from '../common/utils';

// Style variables
const cardStyle = (isRead) => ({
  mb: 2,
  boxShadow: 2,
  backgroundColor: isRead ? 'transparent' : '#f0f0f0', // Change background color for unread notifications
});

const cardContentStyle = {
  fontWeight: 'bold',
};

const dividerStyle = {
  my: 1.5,
};

const boxStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const typographyStyle = {
  color: 'textSecondary',
};

function NotificationItem({ notification, setNotifications }) {
  const dispatch = useDispatch();

  const renderActionButton = (event) => {
    switch (event) {
      case 'renter_order_picked':
        return <Button>Go to Orders</Button>;
      case 'CONTACT_VENDOR':
        return <Button>Contact Vendor</Button>;
      // Add more cases as needed
      default:
        return null;
    }
  };

  const renderMessageText = (event, details) => {
    switch (event) {
      case 'renter_order_picked':
        return `An order has been placed. Order ID: ${details.order_id}`;
      case 'CONTACT_VENDOR':
        return `Please contact the vendor. Vendor ID: ${details.vendor_id}`;
      // Add more cases and messages as needed
      default:
        return 'New notification';
    }
  };

  const markAsRead = () => {
    request(`/mark-notification-read?id=${notification.id}`, 'POST', {})
      .then((res) => {
        if (res.data.is_read) {
          // onRemoveNotification(notification.id);
          setNotifications((preNotifications) => preNotifications.map((n) => {
            if (n.id === notification.id) {
              return {
                ...n,
                is_read: true,
              };
            }
            return n;
          }));
          dispatch(decrementUnreadNotificationCounter());
        }
      })
      .catch((err) => {
        console.error('Error marking notification as read:', err);
      });
  };

  const handleItemClick = () => {
    if (!notification.is_read) {
      markAsRead();
    }
  };

  return (
    <Card variant="outlined" sx={cardStyle(notification.is_read)} onClick={handleItemClick}>
      <CardContent>
        <Typography variant="body1" gutterBottom sx={cardContentStyle}>
          {renderMessageText(notification.event, notification.details)}
        </Typography>
        <Divider sx={dividerStyle} />
        <Box sx={boxStyle}>
          <Typography variant="body2" sx={typographyStyle}>
            Event:
            {' '}
            {notification.event}
          </Typography>
          <Typography variant="body2" sx={typographyStyle}>
            Event:
            {' '}
            {notification.is_read ? 'Read' : 'Unread'}
          </Typography>
          {renderActionButton(notification.event)}
        </Box>
      </CardContent>
    </Card>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setNotifications: PropTypes.func.isRequired,
};

export default NotificationItem;
