// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quill {
  margin-bottom: 32px;
}

.ql-editor {
  font-size: 1rem;
  font-family: "Open Sans",sans-serif;
  height: 150px;
}

.ql-editor.ql-blank::before {
  font-style: normal;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}
`, "",{"version":3,"sources":["webpack://./src/components/vendor/vendor-products/product-form/quill-styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mCAAmC;EACnC,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".quill {\n  margin-bottom: 32px;\n}\n\n.ql-editor {\n  font-size: 1rem;\n  font-family: \"Open Sans\",sans-serif;\n  height: 150px;\n}\n\n.ql-editor.ql-blank::before {\n  font-style: normal;\n  font-weight: 400;\n  color: rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
