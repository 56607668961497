import DOMPurify from 'dompurify';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styles from './styles';
import { request } from '../../../common/utils';
import StyledNotFoundComponent from '../../../common/styled-components/styled-not-found-component';

function VendorProducts() {
  const [products, setProducts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    request('/get-vendor-products', 'GET')
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  }, []);

  const handleProductClick = (productId) => {
    navigate(`/v/product/${productId}/edit`);
  };

  const redirectToAddProduct = () => {
    navigate('/v/add-product');
  };

  const createMarkup = (htmlContent) => ({ __html: DOMPurify.sanitize(htmlContent) });

  if (products.length === 0) {
    return (
      <StyledNotFoundComponent
        content={{
          title: 'No products listed yet',
          message: "Looks like you haven't listed any products yet.",
        }}
        buttonText="Add Product"
        buttonOnClick={redirectToAddProduct}
      />
    );
  }

  return (
    <Box sx={styles.container}>
      <Grid container spacing={2} sx={styles.gridContainer}>
        {products.map((product) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={product.id}
            sx={styles.gridItem}
            onClick={() => handleProductClick(product.id)}
          >
            <Card sx={styles.card}>
              <CardMedia
                component="img"
                sx={styles.squareImage}
                image={product.image.image}
                alt={product.image.alt_text || 'Product image'}
              />
              <CardContent sx={styles.cardContent}>
                <Typography gutterBottom variant="h6" sx={styles.productName}>
                  {product.name}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" sx={styles.productPrice}>
                  {`$${product.price} per day`}
                </Typography>
                <Typography variant="body" sx={styles.productDescription}>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={createMarkup(product.description)} />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Fab color="primary" aria-label="add" sx={styles.fab} onClick={redirectToAddProduct}>
        <AddIcon />
      </Fab>
    </Box>
  );
}

export default VendorProducts;
