import React, { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';

import { request } from '../../../common/utils';
import OrderCard from '../../../common/styled-components/styled-order-card';
import StyledNotFoundComponent from '../../../common/styled-components/styled-not-found-component';

const useStyles = (theme) => ({
  topContainer: {
    marginX: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginX: 'auto',
    },
  },
});

function VendorOrders() {
  const [orders, setOrders] = useState([]);

  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    const statusesCSV = 'pending_approval,approved,declined,inprogress,complete,canceled';
    request(`/v/get-vendor-orders?statuses=${statusesCSV}`, 'GET')
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setOrders(response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching Order info:', error);
      });
  }, []);

  return (
    <Box sx={styles.topContainer}>
      {orders.length > 0 ? (
        orders.map((order) => <OrderCard key={order.id} order={order} userType="vendor" />)
      ) : (
        <StyledNotFoundComponent
          content={{
            title: 'No orders yet',
            message: "Looks like you haven't recieved any orders yet.",
          }}
          showButton={false}
        />
      )}
    </Box>
  );
}

export default VendorOrders;
