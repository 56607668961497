import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import styles from './styles';

function LogIn(props) {
  const {
    openLoginDialog,
    onClose,
    handleLogin,
    email,
    password,
    setEmail,
    setPassword,
    openSignUpDialog,
    handleGoogleLogin,
  } = props;

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const processGoogleLogin = (credentialResponse) => {
    console.log('processGoogleLogin');
    handleGoogleLogin(credentialResponse.credential);
  };

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Dialog
      open={openLoginDialog}
      onClose={onClose}
      onKeyUp={onKeyUp}
      aria-labelledby="login-dialog-title"
      BackdropComponent={() => <Box sx={styles.backdrop} />}
    >
      <DialogTitle id="login-dialog-title">Welcome Back</DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          type="text"
          value={email}
          onChange={handleEmailChange}
          fullWidth
          margin="normal"
          variant="outlined"
          required
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <Typography component="span">
          Don&apos;t have an account?
          <Button
            component="a"
            sx={styles.textOnlyButton}
            onClick={openSignUpDialog}
          >
            Sign up
          </Button>
        </Typography>

        <GoogleLogin
          buttonText="Connect with Google"
          onSuccess={(credentialResponse) => processGoogleLogin(credentialResponse)}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </DialogContent>
      <DialogActions sx={styles.actionContainer}>
        <Button autofocus onClick={handleLogin} sx={styles.actionButton}>
          Login
        </Button>
        <Button onClick={onClose} sx={styles.actionButton}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LogIn.propTypes = {
  openLoginDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handleGoogleLogin: PropTypes.func.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  openSignUpDialog: PropTypes.func.isRequired,
};

LogIn.defaultProps = {
  email: null,
  password: null,
};

export default LogIn;
