import DOMPurify from 'dompurify';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Snackbar,
  MenuItem,
  FormControl,
  Typography,
  useTheme,
  TextField,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import { WithContext as ReactTags, SEPARATORS } from 'react-tag-input';
import { request } from '../../../common/utils';
import 'react-quill/dist/quill.snow.css';

import StyledTextField from '../../../common/styled-components/styled-textfield';
import StyledConfirmationComponent from '../../../common/styled-components/styled-confirmation-dialog';
import UnavailibilityForm from './unavailibility-form';
import useStyles from './styles';
import './tag-styles.css';
import './quill-styles.css';
import StyledButton from '../../../common/styled-components/styled-button';

function ProductForm({ user, mode, product }) {
  const [productCategories, setProductCategories] = useState([]);

  const [tags, setTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [photos, setPhotos] = useState([]);
  const [productName, setProductName] = useState('');
  const [details, setDetails] = useState('');
  const [dailyRate, setDailyRate] = useState('');
  const [depositAmount, setDepositAmount] = useState('');

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const navigate = useNavigate();
  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    if (product) {
      if (product.tags) {
        const tagNames = product.tags.split(',');
        const updatedTags = tagNames.map((name) => ({ id: name, text: name }));
        console.log(updatedTags);
        setTags(updatedTags);
      }
      setSelectedCategory(product.product_category_id || '');
      setPhotos(product.images ? product.images.map((img, index) => ({
        file: {},
        url: img.image,
        alt_text: img.alt_text,
        order: index,
      })) : []);
      setProductName(product.name || '');
      setDetails(product.description || '');
      setDailyRate(product.price || '');
      setDepositAmount(product.deposit || '');

      window.scrollTo(0, 0); // Fixes auto focus on Reacts Tags
    }
  }, [product]);

  useEffect(() => {
    request('/product-categories', 'GET')
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setProductCategories(response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching product categories:', error);
      })
      .finally(() => {
        window.scrollTo(0, 0); // Fixes auto focus on Reacts Tags
      });
  }, []);

  const createMarkup = (htmlContent) => ({ __html: DOMPurify.sanitize(htmlContent) });

  const handlePhotoUpload = async (event) => {
    const selectedPhotos = Array.from(event.target.files);
    const updatedPhotos = [...photos];

    /* eslint-disable no-await-in-loop */
    /* eslint-disable-next-line no-restricted-syntax */
    for (const selectedPhoto of selectedPhotos) {
      try {
        const response = await request(
          '/upload-product-image',
          'POST',
          {
            isFormData: true,
            axiosOptions: { params: { vendor_id: user.vendor_info.id } },
            payload: { file: selectedPhoto },
          },
        );
        updatedPhotos.push({
          file: selectedPhoto,
          url: response.data.s3_url,
          order: updatedPhotos.length,
        });
      } catch (error) {
        console.error('Error uploading photo:', error);
      }
    }

    setPhotos(updatedPhotos);
  };

  const handlePhotoDelete = (photoToDelete) => {
    // Filter out the photo to delete
    const updatedPhotos = photos.filter((photo) => photo.url !== photoToDelete.url);

    // Update the order of the remaining photos
    const reorderedPhotos = updatedPhotos.map((photo, index) => ({
      ...photo,
      order: index, // Assign the new order based on the current index
    }));

    setPhotos(reorderedPhotos);
  };

  const handleSubmit = () => {
    const data = {
      owner_id: user.vendor_info.id,
      name: productName,
      description: details,
      price: parseFloat(dailyRate),
      deposit: parseFloat(depositAmount),
      product_category_id: selectedCategory,
      status: 'active',
      images: photos.map((photo) => ({
        image: photo.url,
        alt_text: `Product photo of ${productName}`,
      })),
      tags: tags.map((tag) => tag.text).join(),
    };
    console.log(data);

    const requestUrl = mode === 'add' ? '/products' : `/products/${product.id}`;
    const requestMethod = mode === 'add' ? 'POST' : 'PUT';
    request(
      requestUrl,
      requestMethod,
      {
        payload: { ...data },
      },
    )
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setAlertMessage('Product added successfully');
          setAlertOpen(true);
          navigate('/v/vendor-dashboard');
        } else {
          throw new Error(res);
        }
      })
      .catch((err) => {
        console.error('Error adding product:', err);
        setAlertMessage('Error adding product');
        setAlertOpen(true);
      });
  };

  const handleDeleteProduct = () => {
    request(
      `/products/${product.id}`,
      'DELETE',
    )
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setAlertMessage('Product deleted successfully');
          setAlertOpen(true);
          navigate('/v/vendor-dashboard');
        } else {
          throw new Error(res);
        }
      })
      .catch((err) => {
        console.error('Error deleting product:', err);
        setAlertMessage('Error deleting product');
        setAlertOpen(true);
      });
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleAvailibilityFormUpdate = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setAlertMessage('');
  };

  const handleTagDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleTagAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleTagDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const renderImageList = (isEditMode) => (
    <ImageList cols={10} gap={16} sx={styles.imageList}>
      {photos.map((image, index) => (
        <ImageListItem sx={styles.imageListItem} key={isEditMode ? image.order : index}>
          <img
            style={styles.image}
            src={image.url}
            alt={isEditMode ? 'Product' : image.alt_text}
          />
          {isEditMode && (
            <ImageListItemBar
              sx={styles.imageListItemBar}
              actionIcon={(
                <IconButton
                  sx={styles.deletePhotoButton}
                  onClick={() => handlePhotoDelete(image)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            />
          )}
        </ImageListItem>
      ))}
      {isEditMode && (
        <ImageListItem style={styles.imageListItem}>
          <IconButton
            style={styles.addPhotoButton}
            color="primary"
            aria-label="upload photo"
            onClick={() => document.getElementById('photo-upload').click()}
          >
            <AddCircleIcon style={styles.addIcon} />
          </IconButton>
          <input
            type="file"
            accept="image/*"
            multiple
            id="photo-upload"
            onChange={handlePhotoUpload}
            style={styles.hiddenFileInput}
          />
        </ImageListItem>
      )}
    </ImageList>
  );

  const renderProductName = (isEditMode) => (
    isEditMode ? (
      <StyledTextField
        label="Product Name"
        placeholder="Name of product to rent"
        fullWidth
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
      />
    ) : (
      <Typography variant="h5" style={styles.viewModeText}>
        {product ? product.name : ''}
      </Typography>
    )
  );

  const renderProductDescription = (isEditMode) => (
    isEditMode ? (
      <ReactQuill
        placeholder="Product Description"
        theme="snow"
        value={details}
        onChange={setDetails}
      />
    ) : (
      <Typography variant="body1" style={styles.viewModeText}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={createMarkup(product ? product.description : '')} />
      </Typography>
    )
  );

  const renderPriceField = (isEditMode) => (
    isEditMode ? (
      <StyledTextField
        label="Price"
        placeholder="Daily Rate for Rental"
        fullWidth
        type="number"
        value={dailyRate}
        onChange={(e) => setDailyRate(e.target.value)}
      />
    ) : (
      <Typography variant="body1" style={styles.viewModeText}>
        {product ? `$${product.price} per day` : ''}
      </Typography>
    )
  );

  const renderSecurityDepositField = (isEditMode) => (
    isEditMode ? (
      <StyledTextField
        label="Estimated Product Value"
        placeholder="Amount for Estimated Product Value"
        fullWidth
        type="number"
        value={depositAmount}
        onChange={(e) => setDepositAmount(e.target.value)}
      />
    ) : (
      <Typography variant="body1" style={styles.viewModeText}>
        {product ? `$${product.deposit} security deposit` : ''}
      </Typography>
    )
  );

  const renderCategorySelect = (isEditMode) => (
    isEditMode ? (
      <FormControl fullWidth style={styles.formControl}>
        <TextField
          variant="outlined"
          label="Product Category"
          select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          style={styles.select}
        >
          {productCategories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    ) : (
      <Typography variant="body1" style={styles.viewModeText}>
        {
            product
              ? `Product Category: ${productCategories.find((category) => category.id === product.product_category_id)?.name}`
              : ''
          }
      </Typography>
    )
  );

  const renderTagsField = (isEditMode) => (
    isEditMode ? (
      <ReactTags
        tags={tags}
        handleDelete={handleTagDelete}
        handleAddition={handleTagAddition}
        handleDrag={handleTagDrag}
        separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
        inputFieldPosition="bottom"
        placeholder="Add new tag"
        style={styles.tagInput}
      />
    ) : (
      <Typography variant="body1" style={styles.viewModeText}>
        {
            product && product.tags
              ? `Tags: ${product.tags}`
              : 'Tags: None'
          }
      </Typography>
    )
  );

  const renderActionButton = (isEditMode, productId) => {
    if (isEditMode) {
      const buttonLabel = mode === 'add' ? 'Add Product' : 'Update Product';
      return (
        <Box sx={styles.buttonContainer}>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={styles.button}
            label={buttonLabel}
          />
          {
            mode === 'edit' && (
              <StyledButton
                variant="contained"
                color="error"
                onClick={handleOpenConfirmationDialog}
                style={styles.deleteButton}
                label="Delete"
              />
            )
          }
        </Box>
      );
    }
    return (
      <StyledButton
        variant="contained"
        color="primary"
        onClick={() => navigate(`/v/product/${productId}/edit`)}
        style={styles.button}
        label="Edit"
      />
    );
  };

  const isEditMode = mode === 'edit' || mode === 'add';

  if (mode !== 'add' && !product) {
    return null;
  }

  console.log(tags);
  return (
    <Box sx={styles.topContainer}>
      {renderImageList(isEditMode)}
      {renderProductName(isEditMode)}
      {renderProductDescription(isEditMode)}
      {renderPriceField(isEditMode)}
      {renderSecurityDepositField(isEditMode)}
      {renderCategorySelect(isEditMode)}
      {renderTagsField(isEditMode)}
      {renderActionButton(isEditMode, product ? product.id : null)}

      {
        mode === 'edit' ? (
          <UnavailibilityForm
            product={product}
            onUpdate={handleAvailibilityFormUpdate}
          />
        ) : null
      }

      <Snackbar
        open={alertOpen}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        message={alertMessage}
      />

      <StyledConfirmationComponent
        open={openConfirmationDialog}
        onClose={handleCloseDialog}
        title="Confirm Action"
        onConfirm={handleDeleteProduct}
        message="Are you sure you want to delete this product?"
      />
    </Box>
  );
}

ProductForm.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  mode: PropTypes.oneOf(['add', 'edit', 'view']).isRequired,
  product: PropTypes.oneOfType([PropTypes.object]),
};

ProductForm.defaultProps = {
  product: {},
};

export default ProductForm;
