const useStyles = (theme) => ({
  page: {
    paddingX: theme.spacing(2),
  },
  orderDetails: {
    [theme.breakpoints.up('md')]: {
      width: '70%',
      padding: theme.spacing(2), // Add padding
      margin: theme.spacing(1), // Add margin
    },
    width: '100%',
    padding: theme.spacing(1), // Add padding for smaller screens
  },
  orderTotals: {
    [theme.breakpoints.up('md')]: {
      width: '30%',
      padding: theme.spacing(2), // Add padding
      margin: theme.spacing(1), // Add margin
    },
    width: '100%',
    padding: theme.spacing(1), // Add padding for smaller screens
  },
  card: {
    marginBottom: theme.spacing(2), // Space between cards
    border: '1px solid #ddd', // Border for cards
    borderRadius: theme.shape.borderRadius, // Use theme's border radius
  },
  header: {
    marginBottom: 3,
  },
  orderLineItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  numebr: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
    },
    marginRight: theme.spacing(3),
  },
  productImage: {
    [theme.breakpoints.up('md')]: {
      width: 100,
      height: 100,
      marginRight: theme.spacing(4),
    },
    width: 80,
    height: 80,
    marginRight: theme.spacing(2),
  },
  listItem: {
    borderBottom: '1px solid #eee', // Border for list items
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  line: {
    width: '60%',
    marginBottom: theme.spacing(3),
  },
  orderPrices: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  dialogContent: {
    padding: theme.spacing(2), // Padding for dialog content
  },
  dialogActions: {
    padding: theme.spacing(1), // Padding for dialog actions
  },
  totalItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  totalItem: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    borderBottom: '1px solid #ddd',
  },
  disclaimerTC: {
    alignItems: 'flex-start',
  },
  disclaimerDeposit: {
    alignItems: 'flex-start',
    marginBottom: 2,
    [theme.breakpoints.down('md')]: {
      marginLeft: 2,
    },
  },
  doubleBorder: {
    borderBottom: '3px double #ddd', // Double line border
  },
  totalLabel: {
    fontWeight: 'bold',
  },
  totalValue: {
    textAlign: 'right',
  },
  checkoutButtonWrapper: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  // Any other specific styles for the button can go here, if needed
  checkoutButton: {
    // Example style
    margin: theme.spacing(1),
  },
  paymentComponent: {
  },
  orderCoupon: {
    marginTop: theme.spacing(2),
  },
  couponApplied: {
    color: 'green',
  },
});

export default useStyles;
