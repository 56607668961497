import { combineReducers } from 'redux';
import userReducer from './user-reducers';
import productReducer from './product-reducers';
import loadingReducer from './loading-reducers';
import unreadNotificationCounter from './notification-counter-reducers';
import ChatReducer from './chat-reducers';
import alertReducer from './alert-reducers';

export default combineReducers({
  user: userReducer,
  product: productReducer,
  loading: loadingReducer,
  unreadNotificationCounter,
  chat: ChatReducer,
  alerts: alertReducer,
});
