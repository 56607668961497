import React from 'react';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

function StyledLoadingComponent({ loading }) {
  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  };

  return (
    loading && (
      <div style={overlayStyles}>
        <CircularProgress size={48} color="primary" thickness={4} />
      </div>
    )
  );
}

StyledLoadingComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default StyledLoadingComponent;
