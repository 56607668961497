import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProductForm from '../product-form/product-form';

function AddProduct(props) {
  const { user } = props;

  return (
    <ProductForm
      user={user}
      mode="add"
    />
  );
}

AddProduct.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

export default connect(mapStateToProps)(AddProduct);
