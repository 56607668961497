import { ADD_ALERT, REMOVE_ALERT, REMOVE_ALL_ALERT } from '../actions/types';

const initialState = [];
let nextAlertId = 0;

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALERT: {
      const { message, severity } = action.payload;
      const newAlert = { id: nextAlertId += 1, message, severity };
      return [...state, newAlert];
    }
    case REMOVE_ALERT: {
      console.log('Removing alert: ', action.payload);
      return state.filter((alert) => alert.id !== action.payload);
    }
    case REMOVE_ALL_ALERT: {
      console.log('Removing all alerts');
      return [];
    }
    default:
      return state;
  }
};

export default alertReducer;
