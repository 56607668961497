const useStyles = (theme) => ({
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginX: 2,
  },
  profilePicture: {
    width: '100px',
    height: '100px',
    margin: '20px auto 20px auto',
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 2,
  },
  editProfileButton: {
    marginRight: '20px',
  },
  addProductRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardAddProduct: {
    width: '100%',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardAddProductContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      gap: 4,
    },
  },
  addProductButton: {
  },
  cardGridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  },
  card: {
    width: '100%',
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default useStyles;
