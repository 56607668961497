import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { removeAlert } from '../../../redux/actions/alert-actions';

const alertStyles = {
  position: 'fixed',
  top: 110,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1500,
};
function AlertsDisplay() {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts);

  console.log('Alerts', alerts);
  useEffect(() => {
    alerts.forEach((alert) => {
      setTimeout(() => {
        console.log('Removing alert', alert);

        dispatch(removeAlert(alert.id));
      }, 5000); // Display each alert for 5 seconds
    });
  }, [alerts, dispatch]);

  if (alerts.length === 0) return null;

  return alerts.map((alert) => (
    <Alert
      key={alert.id}
      severity={alert.severity}
      sx={alertStyles}
      onClick={() => dispatch(removeAlert(alert.id))}
      action={(
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => dispatch(removeAlert(alert.id))}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    >
      {alert.message}
    </Alert>
  ));
}

export default AlertsDisplay;
