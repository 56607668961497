import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import NotificationList from './notification-list';

// Define your style variables here
const popoverStyle = {
  p: 2,
  maxWidth: 400,
  display: 'flex',
  flexDirection: 'column',
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 2,
};

const seeAllButtonStyle = {
  textTransform: 'none',
};

function NotificationDropdown({
  anchorEl, open, onClose, notifications, setNotifications,
}) {
  const navigate = useNavigate();

  const handleSeeAllClick = () => {
    navigate('/notifications');
    onClose(); // Close the popover
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box sx={popoverStyle}>
        <Box sx={headerStyle}>
          <Typography variant="h6">
            Notifications
          </Typography>
          <Button
            onClick={handleSeeAllClick}
            variant="contained"
            color="primary"
            sx={seeAllButtonStyle}
          >
            See All
          </Button>
        </Box>
        <NotificationList notifications={notifications} setNotifications={setNotifications} />
      </Box>
    </Popover>
  );
}

// Define PropTypes
NotificationDropdown.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setNotifications: PropTypes.func.isRequired,
};

NotificationDropdown.defaultProps = {
  anchorEl: [],
};

export default NotificationDropdown;
