import {
  ADD_ALERT,
  REMOVE_ALERT,
  REMOVE_ALL_ALERT,
} from './types';

export const addAlert = (alert) => (dispatch) => {
  dispatch({
    type: ADD_ALERT,
    payload: alert,
  });
};

export const removeAlert = (alertId) => (dispatch) => {
  dispatch({
    type: REMOVE_ALERT,
    payload: alertId,
  });
};

export const removeAllAlert = () => (dispatch) => {
  dispatch({
    type: REMOVE_ALL_ALERT,
  });
};
