import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Rating as MuiRating,
  useTheme,
} from '@mui/material';

const useStyles = () => ({
  ratingInfo: {
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    ratingStars: {
    },
    ratingDetails: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    ratingText: {
      marginLeft: '4px',
    },
    numReviewers: {
      marginLeft: '4px',
      color: 'text.secondary',
    },
  },
});

function RatingInfo({ rating, numReviewers }) {
  const theme = useTheme();
  const styles = useStyles(theme);

  if (numReviewers === 0) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
      >
        No reviews yet
      </Typography>
    );
  }

  return (
    <Box sx={styles.ratingInfo.topContainer}>
      <MuiRating
        sx={styles.ratingInfo.ratingStars}
        name="rating"
        value={rating}
        precision={0.5}
        readOnly
      />
      <Box sx={styles.ratingInfo.ratingDetails}>
        <Typography
          sx={styles.ratingInfo.ratingText}
          variant="body2"
        >
          {rating}
        </Typography>
        <Typography
          sx={styles.ratingInfo.numReviewers}
          variant="body2"
        >
          (
          { numReviewers }
          { numReviewers === 1 ? ' review' : ' reviews' }
          )
        </Typography>
      </Box>
    </Box>
  );
}

RatingInfo.propTypes = {
  rating: PropTypes.number.isRequired,
  numReviewers: PropTypes.number.isRequired,
};

export default RatingInfo;
