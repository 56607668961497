import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import Badge from '@mui/material/Badge';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  // Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import logo from '../../resource/images/logo.svg';
import styles from './styles';
import { request } from './utils';
import NotificationDropdown from '../notification/notification-dropdown';

function NavBar(props) {
  const {
    accountMenuAnchorEl,
    onClickAccount,
    onCloseAccount,
    openSignInDialog,
    user,
    toggleDrawerOpen,
    unreadNotificationCount,
    unreadChatCount,
    cartItemCount,
  } = props;

  const [notifications, setNotifications] = useState([]);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const isNotificationDropdownOpen = Boolean(notificationAnchorEl);

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  // Function to fetch notifications
  const fetchNotifications = () => {
    request('/get-push-notifications', 'GET')
      .then((res) => {
        setNotifications(res.data); // Assuming 'data' contains the notifications
      })
      .catch((err) => {
        console.error('Error fetching notifications:', err);
      });
  };

  useEffect(() => {
    if (isNotificationDropdownOpen) {
      fetchNotifications();
    }
  }, [isNotificationDropdownOpen]);

  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate('/');
  };

  const redirectToChat = () => {
    navigate('/chat');
  };

  const redirectToCart = () => {
    navigate('/cart');
  };

  return (
    <Box sx={styles.topContainer}>
      <AppBar position="static">
        <Toolbar sx={styles.toolbarContainer}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Box component="div" sx={styles.logoContainer}>
            <Box
              component="img"
              src={logo}
              alt="Rentovault"
              sx={styles.logo}
              onClick={redirectToHome}
            />
          </Box>

          { user && user.id && (
            <IconButton sx={styles.navbarIcon} onClick={handleNotificationClick}>
              <Badge badgeContent={unreadNotificationCount} color="warning">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}

          { user && user.id && (
            <IconButton sx={styles.navbarIcon} onClick={redirectToChat}>
              <Badge badgeContent={unreadChatCount} color="warning">
                <ChatRoundedIcon />
              </Badge>
            </IconButton>
          )}

          <IconButton sx={styles.navbarIcon} onClick={redirectToCart}>
            <Badge badgeContent={cartItemCount} color="warning">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>

          <Menu
            anchorEl={accountMenuAnchorEl}
            open={Boolean(accountMenuAnchorEl)}
            onClose={onCloseAccount}
          >
            <MenuItem onClick={openSignInDialog}>Sign in</MenuItem>
            <MenuItem onClick={() => onClickAccount(user)}>Sign up</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <NotificationDropdown
        anchorEl={notificationAnchorEl}
        open={isNotificationDropdownOpen}
        onClose={handleNotificationClose}
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </Box>
  );
}

NavBar.propTypes = {
  accountMenuAnchorEl: PropTypes.oneOfType([PropTypes.elementType]),
  onClickAccount: PropTypes.func.isRequired,
  onCloseAccount: PropTypes.func.isRequired,
  openSignInDialog: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  unreadNotificationCount: PropTypes.number,
  unreadChatCount: PropTypes.number.isRequired,
  cartItemCount: PropTypes.number,
};

NavBar.defaultProps = {
  unreadNotificationCount: 0,
  cartItemCount: 0,
  accountMenuAnchorEl: null,
};

export default NavBar;
