import React, { useEffect, useState } from 'react';
import {
  InputAdornment, useTheme,
} from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { usePlacesWidget } from 'react-google-autocomplete';
import { setLoading, clearLoading } from '../../../redux/actions/loading-actions';
import StyledTextField from './styled-textfield';

const useStyles = () => ({
  searchField: {
  },
  gpsIcon: {
    cursor: 'pointer',
    display: 'none', // Commented out for v0
  },
});

function LocationField(props) {
  const {
    onSelectedPlace,
    place,
    formattedAddress,
    required,
    setLoadingScreen,
    clearLoadingScreen,
  } = props;

  const theme = useTheme();
  const styles = useStyles(theme);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (formattedAddress) {
      setSearchText(formattedAddress);
    } else if (place?.formatted_address) {
      setSearchText(place.formatted_address);
    } else {
      setSearchText('');
    }
  }, [place]);

  const handlePlaceSelected = (newPlace) => {
    setSearchText(newPlace.formatted_address);
    onSelectedPlace(newPlace);
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    options: {
      types: ['address'],
      componentRestrictions: { country: 'ca' },
    },
    onPlaceSelected: handlePlaceSelected,
  });

  const convertCoordsToAddress = (latitude, longitude) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        clearLoadingScreen();
        if (data.status === 'OK') {
          onSelectedPlace(data.results[0]);
        } else {
          console.error('Geocoding failed:', data.status);
        }
      })
      .catch((error) => {
        clearLoadingScreen();
        console.error('Error during geocoding', error);
      });
  };

  const handleLocationButtonClick = async () => {
    setLoadingScreen();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        console.log(latitude);
        console.log(longitude);
        convertCoordsToAddress(latitude, longitude);
      },
      (error) => {
        clearLoadingScreen();
        console.error('Error getting current location: ', error);
      },
    );
  };

  return (
    <>
      <StyledTextField // TODO: Allow change after a location is set
        sx={styles.searchField}
        inputRef={ref}
        name="place"
        label="Location"
        placeholder="Enter a location"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <GpsFixedIcon
                onClick={handleLocationButtonClick}
                sx={styles.gpsIcon}
              />
            </InputAdornment>
          ),
        }}
        required={required}
      />
    </>
  );
}

LocationField.propTypes = {
  onSelectedPlace: PropTypes.func.isRequired,
  place: PropTypes.oneOfType([PropTypes.object]),
  formattedAddress: PropTypes.string,
  required: PropTypes.bool,
  setLoadingScreen: PropTypes.func.isRequired,
  clearLoadingScreen: PropTypes.func.isRequired,
};

LocationField.defaultProps = {
  required: false,
  place: null,
  formattedAddress: null,
};

const enhance = compose(
  connect(
    () => { },
    {
      setLoadingScreen: setLoading,
      clearLoadingScreen: clearLoading,
    },
  ),
);

export default enhance(LocationField);
