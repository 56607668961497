import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function TermsAndConditions() {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions
        </Typography>

        <Box my={2}>
          <Typography variant="h6">1. Introduction</Typography>
          <Typography paragraph>
            Welcome to Rentovault, a peer-to-peer platform for renting photography and outdoor gear.
            By using our services, you agree to comply with and be bound by the following terms and
            conditions.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">2. Definitions</Typography>
          <Typography paragraph>
            &quot;Platform&quot; refers to the Rentovault website and services.
          </Typography>
          <Typography paragraph>
            &quot;User&quot; refers to anyone using the platform, including renters and vendors.
          </Typography>
          <Typography paragraph>
            &quot;Content&quot; refers to all text, images, and other materials provided by users.
          </Typography>
          <Typography paragraph>
            &quot;Listing&quot; refers to the items available for rent on the platform.
          </Typography>
          <Typography paragraph>
            &quot;Rental Period&quot; refers to the agreed duration for which the item is rented.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">3. User Responsibilities</Typography>
          <Typography paragraph>
            Users must provide accurate information during registration and maintain the
            confidentiality of their account details.
          </Typography>
          <Typography paragraph>
            Users are responsible for the gear they rent or list on the platform.
          </Typography>
          <Typography paragraph>Users must comply with all local laws and regulations.</Typography>
          <Typography paragraph>
            Vendors are responsible for updating the order status accurately and in a timely manner.
          </Typography>
          <Typography paragraph>
            Renters are responsible for checking that the order status has been updated during
            pickup and dropoff
          </Typography>
          <Typography paragraph>
            Users are responsible for checking the items during pickup and dropoff to ensure the
            condition of the equipment
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">4. Listing and Renting Gear</Typography>
          <Typography paragraph>
            Vendors must ensure that their gear is accurately described and in good working
            condition.
          </Typography>
          <Typography paragraph>
            Renters must return the gear in the same condition it was received.
          </Typography>
          <Typography paragraph>
            If the Renter damages, loses, steals, converts, transfers custody and control to a
            third-party, and/or fails to return the Owner’s Listing within the Rental Period, the
            Renter agrees that they are liable for and agree to immediately pay for the repair or
            replacement of the Owner’s Listing.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">5. Payments and Fees</Typography>
          <Typography paragraph>
            All transactions must be made using credit cards; cash payments are not accepted.
          </Typography>
          <Typography paragraph>
            Rentovault charges a service fee for each transaction, which will be deducted from the
            payment made to vendors.
          </Typography>
          <Typography paragraph>
            Payment processing and holding funds will be handled securely through our payment system
            until the transaction is complete.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">6. Cancellations and Refunds</Typography>
          <Typography paragraph>
            Cancellations are allowed anytime before the renter picks up the item.
          </Typography>
          <Typography paragraph>Refunds can take up to 7 business days to process.</Typography>
          <Typography paragraph>
            Rentovault handles the refund process and ensures that the appropriate amount is
            returned to the renter.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">7. User Conduct</Typography>
          <Typography paragraph>
            Users must not use the platform for any illegal or unauthorized purpose.
          </Typography>
          <Typography paragraph>
            Users must not engage in any activity that could damage, disable, or impair the
            platform.
          </Typography>
          <Typography paragraph>
            Users must communicate respectfully and fairly during all interactions.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">8. Damage, Claims, and Missing Items</Typography>
          <Typography paragraph>
            Renters must report any damages or missing items immediately upon noticing.
          </Typography>
          <Typography paragraph>
            If the equipment is not returned on time, vendors must notify Rentovault within 48 hours
            of the end date of the rental to initiate recovery procedures.
          </Typography>
          <Typography paragraph>
            Rentovault uses a third-party payment provided (&quot;Square&quot;) to store the
            user&apos;s credit card information securely. Rentovault reserves the right
            to charge the user&apos;s credit card for upto the full amount of the claim
            if the user does not respond to the claim within 48 hours.
          </Typography>
          <Typography paragraph>
            In case of orders exceeding CAD 2000 in value, Rentovault reserves the right to put a hold
            on the user&apos;s credit card for the full amount of the value of the gears to be rented.
            Rentovault reserves the right to cancel the order if the hold fails.
          </Typography>
          <Typography paragraph>
            Rentovault&apos;s reserves the right to take legal actions in case of non-payment of the
            claim amount.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">9. Insurance and Protection</Typography>
          <Typography paragraph>
            The Rentovault Owner Guarantee offers peace of mind to owners of individual Listings
            that are rented out up to $3,000 in Replacement Value.
          </Typography>
          <Typography paragraph>
            Renters are encouraged to have their own insurance policies to cover any potential
            damages or losses.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">10. Dispute Resolution</Typography>
          <Typography paragraph>
            Rentovault will settle disputes between renters and vendors. Users are encouraged to
            first attempt resolution directly but can escalate issues to Rentovault support.
          </Typography>
          <Typography paragraph>
            Any disputes arising from the use of the platform will be resolved through arbitration
            in accordance with applicable laws.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">11. Limitation of Liability</Typography>
          <Typography paragraph>
            Our liability is limited to the amount paid by the user for the rental service.
            Rentovault is not liable for any indirect, incidental, or consequential damages arising
            from the use of the platform.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">12. Modifications to Terms</Typography>
          <Typography paragraph>
            Rentovault reserves the right to modify these terms at any time. Users will be notified
            of any changes through the platform.
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="h6">13. Contact Information</Typography>
          <Typography paragraph>
            Users can contact Rentovault through our website contact page for any queries or
            support.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default TermsAndConditions;
