import React from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import SearchBar from '../common/search-bar';
import Banner from '../common/banner';
import HowItWorks from '../common/how-it-works';
import styles from './styles';

function Home() {
  const navigate = useNavigate();

  const redirectToProductList = () => {
    navigate('/products');
  };

  return (
    <div>
      <Helmet>
        <title>Rentovault</title>
        <meta name="description" content="Rentovault - Your peer-to-peer platform for renting photography and outdoor gear."></meta>
      </Helmet>
      <Grid container spacing={2} alignItems="center" sx={styles.container}>
        <Grid item xs={12} md={7}>
          <Banner />
        </Grid>
        <Grid item xs={12} md={5}>
          <SearchBar
            handleClickOnSearch={redirectToProductList}
            clearOnStart
          />
        </Grid>

        <HowItWorks />

      </Grid>
    </div>
  );
}

export default Home;
