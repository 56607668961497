const styles = {
  container: {
    padding: '16px',
    marginBottom: '16px',
  },
  reviewItem: {
    marginBottom: '32px',
    borderBottom: '1px solid #ccc',
    paddingBottom: '8px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  userAvatar: {
    marginRight: '8px',
  },
  ratingStars: {
  },
  reviewText: {
    marginTop: '8px',
    marginBottom: '8px',
  },
};

export default styles;
