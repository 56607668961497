// UserProfile.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {
  Stack,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Divider,
} from '@mui/material';
import StyledButton from '../common/styled-components/styled-button';
import { request } from '../common/utils';
import {
  StyledAvatar,
  StyledBox,
  StyledRating,
  StyledTypography,
  StyledGrid,
} from './styles';
import RatingInfo from '../common/rating-info';
import calculateRating from '../../utils/common-utils';
import UserReviews from '../review/user-reviews';

function VendorProfile() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState('');
  const [products, setProducts] = useState([]);

  useEffect(() => {
    request(`/user-details/${userId}`, 'GET')
      .then((res) => {
        console.log('User details:', res.data.vendor_info.about_me);
        const sanitizedAbout = DOMPurify.sanitize(res.data.vendor_info.about_me);
        setUser({
          name: `${res.data.first_name} ${res.data.last_name}`,
          location: res.data.location,
          rating: res.data.rating,
          image: res.data.profile_picture?.image,
          about: sanitizedAbout,
        });
        setReviews(res.data.reviews || []);
      })
      .catch((err) => {
        console.error('Error fetching user details:', err);
        setError('Failed to fetch user details.');
      });

    // Fetch vendor products
    request(`/get-vendor-products?user_id=${userId}`, 'GET')
      .then((res) => {
      // Assuming the API response directly contains an array of products
        console.log('Vendor products:', res.data);
        setProducts(res.data);
      })
      .catch((err) => {
        console.error('Error fetching vendor products:', err);
      // Handle error
      });
  }, [userId]);

  function ProductItem({ product }) {
    // Define a method to redirect to the product details page
    const redirectToProductDetails = (id) => {
      navigate(`/product?id=${id}`);
    };

    return (
      <Grid item xs={6} sm={3} sx={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
        <Card sx={{
          width: '100%', maxWidth: 345, mb: 2, boxShadow: 3,
        }}
        >
          <CardActionArea onClick={() => redirectToProductDetails(product.id)} sx={{ cursor: 'pointer' }}>
            <CardMedia
              component="img"
              image={product.image.image}
              alt={product.image.alt_text || product.name}
              sx={{ objectFit: 'cover' }}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                {product.name}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center' }}>
                $
                {product.price.toFixed(2)}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  }

  if (error) {
    return <StyledTypography variant="body1">{error}</StyledTypography>;
  }

  if (!user) {
    return <StyledTypography variant="body1">User not found.</StyledTypography>;
  }

  ProductItem.propTypes = {
    product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  return (
    <StyledBox>
      <StyledAvatar src={user.image} />
      <StyledTypography variant="h5">{user.name}</StyledTypography>
      <RatingInfo
        rating={calculateRating(reviews)}
        numReviewers={reviews.length}
      />
      <StyledGrid item>
        <Stack alignItems="center">
          <StyledButton
            variant="contained"
            label="Contact"
          />
        </Stack>
      </StyledGrid>
      <StyledTypography variant="subtitle1">{user.location}</StyledTypography>
      {user.rating && <StyledRating value={user.rating} readOnly />}
      <StyledTypography variant="body1" dangerouslySetInnerHTML={{ __html: user.about }} />

      {/* Products section */}
      <Divider sx={{ my: 2, bgcolor: 'text.primary' }} />
      <Typography variant="h4" sx={{ margin: (theme) => theme.spacing(2, 0), textAlign: 'center' }}>
        Products listed
      </Typography>

      {products.length > 0 ? (
        <Grid container spacing={2} justifyContent="center">
          {' '}
          {/* Updated justifyContent */}
          {products.map((product) => (
            <ProductItem key={product.id} product={product} />
          ))}
        </Grid>
      ) : (
        <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
          No products found.
        </Typography>
      )}

      {/* Reviews section */}
      <Divider sx={{ my: 2, bgcolor: 'text.primary' }} />
      <Typography variant="h4" sx={{ margin: (theme) => theme.spacing(2, 0), textAlign: 'center' }}>
        Reviews
      </Typography>

      <StyledGrid container spacing={2} justifyContent="center">
        <UserReviews reviews={reviews} />
      </StyledGrid>
    </StyledBox>
  );
}

export default VendorProfile;
