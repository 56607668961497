const styles = {
  contactContainer: {
    marginTop: 3,
    display: 'flex',
    justifyContent: 'center',
    marginX: 1,
    gap: 2,
    flexDirection: 'column',
    '@media (min-width: 540px)': {
      flexDirection: 'row',
      marginX: 15,
    },
  },
  mailBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    flex: 5,
  },
  socialIconContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 3,
  },
  title: {
    display: 'block',
    fontWeight: 500,
    fontSize: '21px',
    marginTop: 5,
    marginLeft: 1,
    '@media (min-width: 540px)': {
      flexDirection: 'row',
      marginX: 15,
    },
  },
  fullNameAndEmailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  },
  textFieldFullName: {
    flex: 1,
  },
  textFieldEmail: {
    flex: 1,
  },
  subjectContainer: {
    display: 'flex',
  },
  multilinedAdorn: {
    '&.MuiInputAdornment-root': {
      height: '100%',
      alignItems: 'flex-start',
      maxHeight: '100%',
    },
  },
  sendButtonContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
  sendMessageButton: {
    fontSize: '18px',
    fontWeight: 600,
    padding: 4,
    paddingY: 2,
    backgroundColor: '#FA6800',
    '&:hover': {
      backgroundColor: '#CC5500',
    },
  },
  contactUs: {
    fontWeight: 500,
    fontSize: '21px',
  },
  socialLinks: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    marginLeft: 2,
    '&:hover': {
      color: '#FA6800',
    },
  },
};

export default styles;
