import React from 'react';
import {
  Container, Typography, Box, Paper, Button,
} from '@mui/material';

function AboutUs() {
  return (
    <Container maxWidth="lg">
      {/* Hero Section */}
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>Bringing Adventures Closer to You</Typography>
        <Typography variant="subtitle1">Your Gateway to a World of Outdoor and Photography Experiences</Typography>
      </Box>

      <Paper sx={{ p: 3, my: 3 }}>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Rentovault is an outdoor gear rental platform dedicated to connecting
          Adventure and Photography enthusiasts.
          By facilitating the sharing of high-quality equipment,
          Rentovault empowers individuals to explore their
          passions without the burden of high costs.
        </Typography>
      </Paper>

      {/* Our Story */}
      <Paper sx={{ p: 3, my: 3 }}>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Committed to safety, trust, and community,
          Rentovault stands as a reliable bridge between gear owners and borrowers,
          ensuring a seamless, secure, and satisfying experience for all.
          Through innovation and service, Rentovault aims to
          foster a sustainable sharing economy,
          enriching adventures and creative pursuits.
        </Typography>
      </Paper>

      <Paper sx={{ p: 3, my: 3 }}>
        <Typography variant="h5">Our Journey</Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Rentovault is an outdoor gear rental platform dedicated to
          connecting Adventure and Photography enthusiasts.
          By facilitating the sharing of high-quality equipment,
          Rentovault empowers individuals to explore their
          passions without the burden of high costs.
        </Typography>
      </Paper>

      {/* Call to Action */}
      <Box sx={{ my: 3, textAlign: 'center' }}>
        <Button variant="contained" color="primary">Join the Community</Button>
      </Box>
    </Container>
  );
}

export default AboutUs;
