import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import StyledTextField from './styled-textfield';

function StyledConfirmationComponent({
  open, onClose, title, message, onConfirm, showNote, noteRequired,
}) {
  const [note, setNote] = useState('');

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>
        {message}
        {
          showNote ? (
            <StyledTextField
              label="Reason"
              placeholder="Share a reason"
              fullWidth
              value={note}
              onChange={(e) => setNote(e.target.value)}
              required
            />
          ) : null
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => { onConfirm(note); }}
          disabled={noteRequired && !note.trim()}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
// Define PropTypes
StyledConfirmationComponent.propTypes = {
  open: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  showNote: PropTypes.bool,
  noteRequired: PropTypes.bool,
};

StyledConfirmationComponent.defaultProps = {
  showNote: false,
  noteRequired: false,
};

export default StyledConfirmationComponent;
