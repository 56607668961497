const useStyles = (theme) => ({
  topContainer: {
    width: '100%',
    marginX: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginX: 'auto',
      paddingX: theme.spacing(6),
      paddingY: theme.spacing(4),
    },
  },
  imageList: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
    paddingY: 4,
    marginBottom: 4,
  },
  imageListItem: {
    width: '200px',
    height: '200px',
    paddingX: 2,
    paddingY: 4,
    alignItems: 'center',
    border: '1px solid lightgrey',
  },
  image: {

  },
  imageListItemBar: {
    position: 'absolute',
    backgroundColor: 'lightgrey',
  },
  deletePhotoButton: {
    color: 'grey',
  },
  addPhotoButton: {
    width: '100%',
    height: '100%',
  },
  addIcon: {
    width: '75%',
    height: '75%',
    color: 'lightgrey',
    opacity: 0.8,
  },
  hiddenFileInput: {
    display: 'none',
  },
  select: {
    marginBottom: theme.spacing(3),
  },
  selectCategoryFormControl: {
    width: '100%',
  },
  selectCategory: {
    display: 'block',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    marginTop: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
  },
  deleteButton: {
    marginLeft: '16px',
  },
  progress: (loading) => ({
    display: loading ? 'block' : 'none',
  }),
});

export default useStyles;
