import { INCREMENT_UNREAD_NOTIFICATION_COUNTER, SET_UNREAD_NOTIFICATION_COUNTER, DECREMENT_UNREAD_NOTIFICATION_COUNTER } from './types';

export const incrementUnreadNotificationCounter = () => (dispatch) => {
  dispatch({
    type: INCREMENT_UNREAD_NOTIFICATION_COUNTER,
  });
};

export const decrementUnreadNotificationCounter = () => (dispatch) => {
  dispatch({
    type: DECREMENT_UNREAD_NOTIFICATION_COUNTER,
  });
};

export const setUnreadNotificationCount = (count) => (dispatch) => {
  dispatch({
    type: SET_UNREAD_NOTIFICATION_COUNTER,
    payload: count,
  });
};
