import { SET_UNREAD_NOTIFICATION_COUNTER, INCREMENT_UNREAD_NOTIFICATION_COUNTER, DECREMENT_UNREAD_NOTIFICATION_COUNTER } from '../actions/types';

const initialState = {
  count: 0,
};

const unreadNotificationCounterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNREAD_NOTIFICATION_COUNTER:
      return { ...state, count: action.payload };
    case INCREMENT_UNREAD_NOTIFICATION_COUNTER:
      return { ...state, count: state.count + 1 };
    case DECREMENT_UNREAD_NOTIFICATION_COUNTER:
      if (state.count > 0) {
        return { ...state, count: state.count - 1 };
      }
      return state;
    default:
      return state;
  }
};

export default unreadNotificationCounterReducer;
