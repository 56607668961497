import {
  React,
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Box,
  Avatar,
  Card,
  CardContent,
  Grid,
  useTheme,
} from '@mui/material';
import { request } from '../../common/utils';
import useStyles from './styles';

function VendorDashboard({ user }) {
  const [vendorInfo, setVendorInfo] = useState({
    num_complete_orders: 0,
    num_ongoing_orders: 0,
    num_reviews: 0,
    num_products: 0,
  });

  const navigate = useNavigate();
  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    // Check if the user is a vendor and has vendor_info
    console.log(user);
    if (!user || user.role !== 'vendor' || !user.vendor_info) {
      navigate('/v/vendor-signup');
    }
  });

  useEffect(() => {
    request(`/${user.id}/vendor-dashboard-info`, 'GET')
      .then((res) => {
        setVendorInfo({
          num_complete_orders: res.data.num_complete_orders,
          num_ongoing_orders: res.data.num_ongoing_orders,
          num_reviews: res.data.num_reviews,
          num_products: res.data.num_products,
        });
      })
      .catch((err) => console.log(err));
  }, [user]);

  const handleEditProfileClick = () => {
    navigate('/self-profile');
  };

  const handleOrdersClick = () => {
    navigate('/v/orders');
  };

  const handleReviewsClick = () => {
    navigate('/v/reviews');
  };

  const handleProductsClick = () => {
    navigate('/v/products');
  };

  const handleAddProduct = () => {
    navigate('/v/add-product');
  };

  const fullName = `${user.first_name} ${user.last_name}`;
  return (
    <Box sx={styles.topContainer}>
      <Avatar
        alt={user.first_name}
        src={user.profile_picture ? user.profile_picture.image : null}
        sx={styles.profilePicture}
      />
      <Typography sx={styles.name} variant="h4">{fullName}</Typography>
      <Button
        variant="text"
        color="primary"
        style={styles.editProfileButton}
        onClick={handleEditProfileClick}
      >
        Edit Profile
      </Button>

      <Grid container spacing={2}>
        {vendorInfo.num_products === 0 && (
          <Grid item xs={12} sx={styles.addProductRow}>
            <Card variant="outlined" onClick={handleAddProduct} sx={styles.cardAddProduct}>
              <CardContent sx={styles.cardAddProductContent}>
                <Typography variant="subtitle1">You do not have any products Listed.</Typography>
                <Button sx={styles.addProductButton} variant="text">Add Product</Button>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} sx={styles.cardGridItem}>
          <Card variant="outlined" sx={styles.card} onClick={handleOrdersClick}>
            <CardContent sx={styles.cardContent}>
              <Typography variant="h1">{vendorInfo.num_ongoing_orders}</Typography>
              <Typography variant="h5" align="center">Orders In Progress</Typography>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={styles.card} onClick={handleOrdersClick}>
            <CardContent sx={styles.cardContent}>
              <Typography variant="h1">{vendorInfo.num_complete_orders}</Typography>
              <Typography variant="h5" align="center">Orders Completed</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sx={styles.cardGridItem}>
          <Card variant="outlined" sx={styles.card} onClick={handleReviewsClick}>
            <CardContent sx={styles.cardContent}>
              <Typography variant="h1">{vendorInfo.num_reviews}</Typography>
              <Typography variant="h5" align="center">Reviews</Typography>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={styles.card} onClick={handleProductsClick}>
            <CardContent sx={styles.cardContent}>
              <Typography variant="h1" align="center">{vendorInfo.num_products}</Typography>
              <Typography variant="h5">Items Listed</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

VendorDashboard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string,
    vendor_info: PropTypes.shape({
      about: PropTypes.string,
    }),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    profile_picture: PropTypes.string,
  }),
};

VendorDashboard.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

export default connect(mapStateToProps)(VendorDashboard);
