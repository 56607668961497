import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { request } from '../../../common/utils';
import StyledTextField from '../../../common/styled-components/styled-textfield';

const styles = {
  topContainer: {
    display: 'none', // Hiding in beta version
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '16px',
  },
  heading: {
  },
  unavailableDatesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '16px',
  },
  button: {
    marginTop: '16px',
  },
};

function UnavailibilityForm({ product, onUpdate }) {
  const [unavailibilities, setUnavailibilities] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  console.log(product);

  useEffect(() => {
    request(`/products/${product.id}/availibility`, 'GET')
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setUnavailibilities(res.data);
        } else {
          throw new Error(res);
        }
      })
      .catch((err) => {
        console.error('Error fetching availibility:', err);
      });
  }, [product]);

  const handleUpdateUnavailableDates = () => {
    const data = {
      start_time: startDate,
      end_time: endDate,
      product_id: product.id,
    };
    console.log(data);

    request(
      `/products/${product.id}/add-availibility`,
      'POST',
      {
        payload: { ...data },
      },
    )
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          onUpdate('Dates marked as unavailable');
        } else {
          throw new Error(res);
        }
      })
      .catch((err) => {
        console.error('Error marking dates as unavailable:', err);
        onUpdate('Error marking dates as unavailable');
      });
  };

  const deleteUnavailibity = (availibilityId) => {
    request(
      `/products/${product.id}/availibility/${availibilityId}`,
      'DELETE',
    )
      .then((res) => {
        if (res.status === 200) {
          setUnavailibilities(res.data);
        } else {
          throw new Error(res);
        }
      })
      .catch((err) => {
        console.error('Error marking dates as unavailable:', err);
        onUpdate('Error marking dates as unavailable');
      });
  };

  return (
    <Box sx={styles.topContainer}>
      <Typography
        sx={styles.heading}
      >
        Mark dates when product is unavailable:
      </Typography>

      <List>
        {unavailibilities.map((unavailibility) => (
          <ListItem
            key={unavailibility.id}
            secondaryAction={(
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => deleteUnavailibity(unavailibility.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          >
            <ListItemText
              primary={`${new Date(unavailibility.start_time).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${new Date(unavailibility.end_time).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`}
            />
          </ListItem>
        ))}
      </List>

      <Box sx={styles.unavailableDatesContainer}>
        <StyledTextField
          type="date"
          name="startDate"
          value={startDate}
          label="Start date"
          placeholder="Start date"
          onChange={(e) => setStartDate(e.target.value)}
        />
        <StyledTextField
          type="date"
          name="endDate"
          value={endDate}
          label="End date"
          placeholder="End date"
          onChange={(e) => setEndDate(e.target.value)}
        />
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateUnavailableDates}
        style={styles.button}
      >
        Mark Dates as Unavailable
      </Button>
    </Box>
  );
}

UnavailibilityForm.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default UnavailibilityForm;
