import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import howitworks1 from '../../resource/images/howitworks1.webp';
import howitworks2 from '../../resource/images/howitworks2.webp';

const useStyles = (theme) => ({
  heading: {
    width: '100%',
    textAlign: 'center',
    marginTop: 8,
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
    },
  },
  subHeading: {
    width: '100%',
    textAlign: 'center',
    marginTop: 4,
    textDecoration: 'underline',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  },
  images: {
    width: '100%',
  },
});

function HowItWorks() {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography variant="h2" sx={styles.heading}>
          How it works
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h3" sx={styles.subHeading}>Renter</Typography>
        <img src={howitworks1} alt="How it works - renter" style={styles.images} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h3" sx={styles.subHeading}>Vendor</Typography>
        <img src={howitworks2} alt="How it works - renter" style={styles.images} />
      </Grid>
    </>
  );
}

export default HowItWorks;
