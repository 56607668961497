import {
  SET_SEARCH_FILTER,
  CLEAR_SEARCH_FILTER,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const setSearchFilter = (data) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_FILTER,
    payload: data,
  });
};

export const clearSearchFilter = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_FILTER,
  });
};
