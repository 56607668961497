import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItemText,
  Typography,
  ListItem,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import styles from './styles';

function LeftSidebar(props) {
  const {
    user,
    toggleDrawerOpen,
    openSignInDialog,
    openSignUpDialog,
    handleLogout,
  } = props;

  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate('/');
    toggleDrawerOpen();
  };

  const redirectToOrders = () => {
    navigate('/orders');
    toggleDrawerOpen();
  };

  const redirectToVendorSignup = () => {
    navigate('/v/vendor-signup');
    toggleDrawerOpen();
  };

  const redirectToVendorDashboard = () => {
    navigate('/v/vendor-dashboard');
    toggleDrawerOpen();
  };

  const redirectToAdminDashboard = () => {
    navigate('/a/admin-dashboard');
    toggleDrawerOpen();
  };

  const redirectToContact = () => {
    toggleDrawerOpen();
    navigate('/contact');
  };

  const redirectToSelfProfile = () => {
    toggleDrawerOpen();
    navigate('/self-profile');
  };

  const redirectToTermsAndConditions = () => {
    toggleDrawerOpen();
    navigate('/terms-and-conditions');
  };

  const redirectToFAQ = () => {
    toggleDrawerOpen();
    navigate('/faq');
  };

  const redirectToAboutUs = () => {
    toggleDrawerOpen();
    navigate('/about-rentovault');
  };

  return (
    <Box>
      <Box sx={styles.drawerHeader}>
        <Box sx={styles.headerImage}>
          {user && user.id && (
            <>
              <Avatar
                sx={styles.profile}
                src={user.profile_picture ? user.profile_picture.image : null}
              >
                {user.first_name.charAt(0)}
              </Avatar>
              <Box sx={styles.headerName}>
                <Typography variant="h3" sx={styles.namePart}>
                  {`${user.first_name} ${user.last_name}`}
                </Typography>

              </Box>
            </>
          )}
        </Box>
        <Box sx={styles.headerAction}>
          <IconButton onClick={toggleDrawerOpen}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={styles.drawerBody}>
        <List>
          <ListItem
            onClick={redirectToHome}
            sx={styles.buttonItem}
          >
            <ListItemText sx={styles.sideBarButtonText}>
              Home
            </ListItemText>
          </ListItem>

          { // Profile
            user && user.id && (
              <ListItem
                sx={styles.buttonItem}
                onClick={redirectToSelfProfile}
              >
                <ListItemText sx={styles.sideBarButtonText}>
                  Profile
                </ListItemText>
              </ListItem>
            )
          }

          { // Orders
            user && user.id && (
              <ListItem
                sx={styles.buttonItem}
                onClick={redirectToOrders}
              >
                <ListItemText
                  sx={styles.sideBarButtonText}
                >
                  Orders
                </ListItemText>
              </ListItem>
            )
          }

          <ListItem
            sx={styles.buttonItem}
            onClick={redirectToAboutUs}
          >
            <ListItemText sx={styles.sideBarButtonText}>
              About Rentovault
            </ListItemText>
          </ListItem>

          <ListItem
            sx={styles.buttonItem}
            onClick={redirectToTermsAndConditions}
          >
            <ListItemText sx={styles.sideBarButtonText}>
              Terms & Conditions
            </ListItemText>
          </ListItem>

          <ListItem
            sx={styles.buttonItem}
            onClick={redirectToFAQ}
          >
            <ListItemText sx={styles.sideBarButtonText}>
              FAQ
            </ListItemText>
          </ListItem>

          <ListItem
            sx={styles.buttonItem}
            onClick={redirectToContact}
          >
            <ListItemText sx={styles.sideBarButtonText}>
              Contact
            </ListItemText>
          </ListItem>

          {
            user && user.id ? (
              <ListItem
                sx={styles.buttonItem}
                onClick={handleLogout}
              >
                <ListItemText sx={styles.sideBarButtonText}>
                  Logout
                </ListItemText>
              </ListItem>
            ) : (
              <>
                <ListItem
                  sx={styles.buttonItem}
                  onClick={openSignInDialog}
                >
                  <ListItemText sx={styles.sideBarButtonText}>
                    Login
                  </ListItemText>
                </ListItem>
                <ListItem
                  sx={styles.buttonItem}
                  onClick={openSignUpDialog}
                >
                  <ListItemText sx={styles.sideBarButtonText}>
                    Signup
                  </ListItemText>
                </ListItem>
              </>
            )
          }

          {
            user && user.id && (
              <ListItem>
                <Button
                  component="v"
                  sx={{ ...styles.textOnlyButton, ...styles.sideBarButtonText }}
                  onClick={(user.role === 'vendor' || user.role === 'admin') && user.vendor_info ? redirectToVendorDashboard : redirectToVendorSignup}
                >

                  {(user.role === 'vendor' || user.role === 'admin') && user.vendor_info ? 'Vendor Dashboard' : 'Become A Vendor' }
                </Button>
              </ListItem>
            )
          }

          {user && user.role === 'admin' && (
            <ListItem>
              <Button
                sx={{ ...styles.textOnlyButton, ...styles.sideBarButtonText }}
                onClick={redirectToAdminDashboard}
              >
                Admin Dashboard
              </Button>
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  );
}

LeftSidebar.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
  toggleDrawerOpen: PropTypes.func.isRequired,
  openSignInDialog: PropTypes.func.isRequired,
  openSignUpDialog: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

LeftSidebar.defaultProps = {
  user: {},
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

const enhance = compose(
  connect(
    mapStateToProps,
    {},
  ),
);

export default enhance(LeftSidebar);
