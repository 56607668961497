// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tags-wrapper {
  width: 100%;
}

.ReactTags__selected {
  margin: 16px 0 16px 0;
}

.ReactTags__tag {
  width: 100px;
  background-color: #4ba4ff;
  padding: 8px 16px 8px 16px;
  margin-left: 8px;
  border-radius: 16px;
}

.ReactTags__tag > button {
  width: 4px;
  padding: 0px 2px 0px 2px;
  margin-left: 8px;
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0px;
}

.ReactTags__tagInput {
  margin: 16px 0 16px 0;
}

.ReactTags__tagInputField {
  width: 95%;
  height: 1.4375em;
  padding: 16.5px 0 16.5px 14px;
  font-size: 1rem;
  font-family: "Open Sans",sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/vendor/vendor-products/product-form/tag-styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,0BAA0B;EAC1B,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,wBAAwB;EACxB,gBAAgB;EAChB,SAAS;EACT,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,6BAA6B;EAC7B,eAAe;EACf,mCAAmC;AACrC","sourcesContent":[".react-tags-wrapper {\n  width: 100%;\n}\n\n.ReactTags__selected {\n  margin: 16px 0 16px 0;\n}\n\n.ReactTags__tag {\n  width: 100px;\n  background-color: #4ba4ff;\n  padding: 8px 16px 8px 16px;\n  margin-left: 8px;\n  border-radius: 16px;\n}\n\n.ReactTags__tag > button {\n  width: 4px;\n  padding: 0px 2px 0px 2px;\n  margin-left: 8px;\n  border: 0;\n  background: none;\n  box-shadow: none;\n  border-radius: 0px;\n}\n\n.ReactTags__tagInput {\n  margin: 16px 0 16px 0;\n}\n\n.ReactTags__tagInputField {\n  width: 95%;\n  height: 1.4375em;\n  padding: 16.5px 0 16.5px 14px;\n  font-size: 1rem;\n  font-family: \"Open Sans\",sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
