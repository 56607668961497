import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card, CardContent, CardActionArea, Typography,
} from '@mui/material';

function SummaryCard({ title, value, link }) {
  const navigate = useNavigate();

  const redirectToLink = () => {
    navigate(link);
  };

  return (
    <Card onClick={redirectToLink} sx={{ cursor: 'pointer' }}>
      <CardActionArea>
        <CardContent>
          <Typography variant="h5" component="h2">{value}</Typography>
          <Typography color="textSecondary">{title}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

SummaryCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object]).isRequired,
  value: PropTypes.oneOfType([PropTypes.object]).isRequired,
  link: PropTypes.string.isRequired,
};

export default SummaryCard;
