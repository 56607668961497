import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import PropTypes from 'prop-types';
import { request } from '../../common/utils';

function SquarePaymentComponent({ totalAmount }) {
  const [paymentResult, setPaymentResult] = useState(null);

  const navigate = useNavigate();

  const handlePaymentSuccess = (res) => {
    // Assuming res.data contains orderIds as a CSV string
    const orderIds = res.data;
    navigate(`/order-confirmation/${orderIds}`);
    setPaymentResult(true);
  };

  const handlePaymentError = (err) => {
    console.error('Server error:', err.statusText);
    setPaymentResult(false);
  };
  const squareApplicationId = process.env.REACT_APP_SQUARE_APPLICATION_ID;
  const squareLocationId = process.env.REACT_APP_SQUARE_LOCATION_ID;

  return (
    <div>
      {/* <h1>Square Payment Integration</h1> */}
      <PaymentForm
        applicationId={squareApplicationId}
        locationId={squareLocationId}
        cardTokenizeResponseReceived={async (token) => {
          request(
            '/checkout',
            'POST',
            {
              payload: {
                source_id: token.token,
                location_id: squareLocationId,
                amount: totalAmount,
              },
            },
          )
            .then(handlePaymentSuccess)
            .catch(handlePaymentError);
        }}
      >
        <CreditCard />
      </PaymentForm>
      {paymentResult && (
        <div>
          <h2>Payment Successful</h2>
          <p>
            Transaction ID:
            {paymentResult.transaction.id}
          </p>
        </div>
      )}
    </div>
  );
}

// Add PropTypes for totalAmount
SquarePaymentComponent.propTypes = {
  totalAmount: PropTypes.number.isRequired,
};

export default SquarePaymentComponent;
