import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Box, useTheme } from '@mui/material';
import ChatThreadItem from './chat-thread-item';
import StyledNotFoundComponent from '../common/styled-components/styled-not-found-component'; // Import the component

import useStyles from './styles';

function ChatThreads(props) {
  const { chatThreads } = props;

  const theme = useTheme();
  const styles = useStyles(theme);

  if (chatThreads.length === 0) {
    // Display StyledNotFoundComponent when chatThreads is empty
    return (
      <StyledNotFoundComponent
        content={{
          title: 'No Conversations',
          message: 'It looks like you don\'t have any conversations yet.',
        }}
        showButton={false}
      />
    );
  }

  return (
    <Box sx={styles.chatThreadsContainer}>
      <List>
        {chatThreads
          .sort(
            (a, b) => new Date(b.latest_message_timestamp)
              - new Date(a.latest_message_timestamp),
          )
          .map((thread) => (
            <ChatThreadItem key={thread.id} thread={thread} />
          ))}
      </List>
    </Box>
  );
}

ChatThreads.propTypes = {
  chatThreads: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};

const mapStateToProps = (state) => ({
  chatThreads: state.chat.chatThreads,
});

export default connect(mapStateToProps)(ChatThreads);
