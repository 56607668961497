import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Collapse,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { makeStyles } from '@mui/styles';

// Utility function to handle nested properties
const getNestedProperty = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);

const renderCompositeValue = (row, fields) => fields.map((field) => getNestedProperty(row, field)).filter(Boolean).join(' ');

const useStyles = makeStyles({
  collapseCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  nestedTable: {
    margin: '16px',
    padding: '16px',
    border: '1px solid #ccc',
    borderRadius: '8px',
  },
});

function AdminTable({
  headers,
  data,
  onEdit,
  onDelete,
  nestedDataKey,
  nestedHeaders,
  showEditButton,
  showDeleteButton,
  onDeleteNested,
}) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedNestedItem, setSelectedNestedItem] = useState(null);
  const classes = useStyles();

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedRow(null);
  };

  const handleConfirmDelete = () => {
    onDelete(selectedRow);
    handleCloseDialog();
  };

  const handleExpandClick = (rowId) => {
    setExpandedRows((prevExpandedRows) => (
      prevExpandedRows.includes(rowId)
        ? prevExpandedRows.filter((id) => id !== rowId)
        : [...prevExpandedRows, rowId]
    ));
  };

  const handleDeleteNestedClick = (nestedItem) => {
    setSelectedNestedItem(nestedItem);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDeleteNested = () => {
    onDeleteNested(selectedNestedItem.order_id, selectedNestedItem);
    handleCloseDialog();
  };

  return (
    <Card>
      <CardContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header.id}>{header.label}</TableCell>
                ))}
                {(showEditButton || showDeleteButton || nestedDataKey)
                && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <React.Fragment key={row.id || row.code}>
                  <TableRow>
                    {headers.map((header) => (
                      <TableCell key={header.id}>
                        {header.composite
                          ? renderCompositeValue(row, header.fields)
                          : getNestedProperty(row, header.id)}
                      </TableCell>
                    ))}
                    {(showEditButton || showDeleteButton || nestedDataKey) && (
                      <TableCell>
                        {showEditButton && (
                          <IconButton onClick={() => onEdit(row)}>
                            <EditIcon />
                          </IconButton>
                        )}
                        {showDeleteButton && (
                          <IconButton onClick={() => handleDeleteClick(row)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                        {nestedDataKey && (
                          <IconButton onClick={() => handleExpandClick(row.id)}>
                            {expandedRows.includes(row.id)
                              ? <ExpandLessIcon />
                              : <ExpandMoreIcon />}
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                  {nestedDataKey && (
                    <TableRow>
                      <TableCell
                        className={classes.collapseCell}
                        colSpan={headers.length + 1}
                      >
                        <Collapse
                          in={expandedRows.includes(row.id)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box className={classes.nestedTable}>
                            <Table size="small" aria-label="nested-data">
                              <TableHead>
                                <TableRow>
                                  {nestedHeaders.map((nestedHeader) => (
                                    <TableCell key={nestedHeader.id}>
                                      {nestedHeader.label}
                                    </TableCell>
                                  ))}
                                  <TableCell>Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {getNestedProperty(row, nestedDataKey).map((nestedItem) => (
                                  <TableRow key={nestedItem.id}>
                                    {nestedHeaders.map((nestedHeader) => (
                                      <TableCell key={nestedHeader.id}>
                                        {getNestedProperty(nestedItem, nestedHeader.id)}
                                      </TableCell>
                                    ))}
                                    <TableCell>
                                      <IconButton
                                        onClick={() => handleDeleteNestedClick(nestedItem)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={selectedNestedItem ? handleConfirmDeleteNested : handleConfirmDelete}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

AdminTable.propTypes = {
  headers: PropTypes.oneOfType([PropTypes.object]).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  nestedDataKey: PropTypes.string,
  nestedHeaders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
  })),
  showEditButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  onDeleteNested: PropTypes.func,
};

AdminTable.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  nestedDataKey: null,
  nestedHeaders: [],
  showEditButton: false,
  showDeleteButton: false,
  onDeleteNested: () => {},
};

export default AdminTable;
