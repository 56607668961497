import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

function LoginPage({ user }) {
  const navigate = useNavigate();

  const redirect = () => {
    // no-restricted-globals
    // const redirectUrl = window.location.search.split('redirectUrl=')[1];
    // navigate(redirectUrl);
    navigate(-1);
  };

  useEffect(() => {
    if (user && user.id) {
      redirect();
    }
  }, [user]);

  return (
    <div />
  );
}

LoginPage.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
};

LoginPage.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

export default connect(mapStateToProps)(LoginPage);
