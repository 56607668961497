import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { Box, useTheme } from '@mui/material';
import 'react-image-gallery/styles/css/image-gallery.css';
import './styles.css';
import useStyles from './styles';

function ProductImages({ images }) {
  const theme = useTheme();
  const styles = useStyles(theme);

  const galleryImages = images.map((img) => ({
    original: img.image,
    thumbnail: img.image,
  }));

  return (
    <Box sx={styles.mainImageBoxStyle}>
      <ImageGallery
        items={galleryImages}
        showPlayButton={false}
        showFullscreenButton={false}
        thumbnailPosition="bottom"
        additionalClass="image-gallery-thumbnails-container"
        showNav
        thumbnailClass="image-gallery-thumbnail"
        originalClass="image-gallery-original"
      />
    </Box>
  );
}

ProductImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    img_url: PropTypes.string.isRequired,
  })).isRequired,
};

export default ProductImages;
