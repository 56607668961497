/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField as MUITextField } from '@mui/material';
import PropTypes from 'prop-types';

function StyledTextField(props) {
  const { sx, ...restProps } = props;

  const customSx = {
    marginBottom: 4,
  };

  const mergedSx = { ...customSx, ...sx };

  return (
    <MUITextField
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      sx={mergedSx}
      {...restProps} // Spread the remaining props
    />
  );
}

StyledTextField.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object]),
};

StyledTextField.defaultProps = {
  sx: {},
};

export default StyledTextField;
