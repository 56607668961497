import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import SummaryCard from './summary-card';

function UsersSummary({ userCount, vendorCount }) {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <SummaryCard
            title="Total Users"
            value={userCount}
            link="/a/user-management"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SummaryCard
            title="Total Vendors"
            value={vendorCount}
            link="/a/user-management"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

// Add prop validation
UsersSummary.propTypes = {
  userCount: PropTypes.number.isRequired,
  vendorCount: PropTypes.number.isRequired,
};

export default UsersSummary;
