export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR-USER';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const CLEAR_SEARCH_FILTER = 'CLEAR_SEARCH_FILTER';
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const SET_UNREAD_NOTIFICATION_COUNTER = 'SET_UNREAD_NOTIFICATION_COUNTER';
export const INCREMENT_UNREAD_NOTIFICATION_COUNTER = 'INCREMENT_UNREAD_NOTIFICATION_COUNTER';
export const DECREMENT_UNREAD_NOTIFICATION_COUNTER = 'DECREMENT_UNREAD_NOTIFICATION_COUNTER';
export const SET_CHAT_THREADS = 'SET_CHAT_THREADS';
export const CLEAR_CHAT_THREADS = 'CLEAR_CHAT_THREADS';
export const SET_CHAT_THREAD_SEEN = 'SET_CHAT_THREAD_SEEN';
export const SET_NEW_CHAT_THREAD = 'SET_NEW_CHAT_THREAD';
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALL_ALERT = 'REMOVE_ALL_ALERT';
