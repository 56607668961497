import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

// Utility functions to handle nested properties
const getNestedProperty = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);

const setNestedProperty = (obj, path, value) => {
  const keys = path.split('.');
  const lastKey = keys.pop();
  const deepObject = keys.reduce((acc, key) => {
    if (!acc[key]) {
      acc[key] = {};
    }
    return acc[key];
  }, obj);
  deepObject[lastKey] = value;
};

function AdminModal({
  open, onClose, initialData = {}, onSave, fields,
}) {
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    setFormData(initialData); // Reset form data when the modal is opened with new initial data
  }, [initialData, open]);

  const handleChange = (e) => {
    const {
      name, value, checked, type,
    } = e.target;
    const newFormData = { ...formData };
    setNestedProperty(newFormData, name, type === 'checkbox' ? checked : value);
    setFormData(newFormData);
  };

  const handleSave = () => {
    onSave(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{initialData ? 'Edit' : 'Add New'}</DialogTitle>
      <DialogContent>
        {fields.map((field) => {
          if (field.type === 'select') {
            return (
              <FormControl fullWidth margin="dense" key={field.id}>
                <InputLabel>{field.label}</InputLabel>
                <Select
                  name={field.id}
                  value={getNestedProperty(formData, field.id) || ''}
                  label={field.label}
                  onChange={handleChange}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          } if (field.type === 'checkbox') {
            return (
              <FormGroup key={field.id}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!getNestedProperty(formData, field.id)}
                      onChange={handleChange}
                      name={field.id}
                    />
                )}
                  label={field.label}
                />
              </FormGroup>
            );
          }
          return (
            <TextField
              key={field.id}
              margin="dense"
              name={field.id}
              label={field.label}
              type={field.type}
              fullWidth
              variant="outlined"
              value={getNestedProperty(formData, field.id) || ''}
              onChange={handleChange}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

AdminModal.propTypes = {
  open: PropTypes.bool.isRequired,
  fields: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialData: PropTypes.oneOfType([PropTypes.object]),
};

AdminModal.defaultProps = {
  initialData: {},
};

export default AdminModal;
