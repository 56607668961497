import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import ProductForm from '../product-form/product-form';
import { request } from '../../../common/utils';

function ProductDetails(props) {
  const { user } = props;

  const [product, setProduct] = useState(null);
  const { productId } = useParams();

  useEffect(() => {
    console.log('Making request');
    request(`/products/${productId}`, 'GET')
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setProduct(response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching product:', error);
      });
  }, []);

  return (
    <ProductForm
      user={user}
      product={product}
      mode="view"
    />
    // <p>sasa</p>
  );
}

ProductDetails.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

export default connect(mapStateToProps)(ProductDetails);
