import React, {
  useState,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import {
  List,
  ListItem,
  Avatar,
  Typography,
  TextField,
  Button,
  Box,
  useTheme,
} from '@mui/material';
import { request } from '../common/utils';
import { setChatThreadSeen } from '../../redux/actions/chat-actions';
import { store } from '../../redux/configureStore';
import useStyles from './styles';

function ChatMessagesPage({ user }) {
  const { targetUserId } = useParams();
  const [messages, setMessages] = useState([]);
  const [thread, setThread] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const [error, setError] = useState('');

  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    if (user && user.id) {
      request(`/messages?target_user_id=${targetUserId}`, 'GET')
        .then((res) => {
          console.log(res);
          setThread(res.data.thread);
          if (res.data.messages.length !== 0) {
            const latestMessageId = res.data.messages[0].id;
            setMessages([...messages, ...res.data.messages.reverse()]);
            request(`/messages/${latestMessageId}/seen`, 'POST');
            store.dispatch(setChatThreadSeen(thread.id));
          }
        })
        .catch((err) => {
          console.error('Error fetching messages:', err);
          setError('Error fetching messages');
        });
    }
  }, []);

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const otherUser = thread.first_user.id === user.id ? thread.second_user : thread.first_user;

      request(
        `/send-message?from_user_id=${user.id}&to_user_id=${otherUser.id}&message=${newMessage}`,
        'POST',
      )
        .then((res) => {
          // Update the messages list or fetch messages again
          setMessages([...messages, res.data]);
          setNewMessage(''); // Clear the input field
        })
        .catch((err) => {
          console.error('Error sending message:', err);
          // Handle send message error
        });

      ReactGA.event({
        category: 'Sending message',
        action: 'send_message',
        label: `${user.id} sending message to ${otherUser.id}`,
      });
    }
  };

  if (error) {
    return <div>Error</div>;
  }

  let otherUser; let
    switchUser;
  if (Object.keys(thread).length !== 0) {
    otherUser = thread.first_user.id === user.id ? thread.second_user : thread.first_user;
    switchUser = messages.map(
      (message, idx, array) => idx === 0 || message.from_user_id !== array[idx - 1].from_user_id,
    );
  }

  return (
    !otherUser ? null : (
      <Box sx={styles.chatMessagesContainer}>
        <Box sx={styles.recipientDetailsContainer}>
          <Avatar
            sx={styles.recipientAvatar}
            src={otherUser.profile_picture.image}
          >
            {otherUser.first_name.charAt(0)}
          </Avatar>
          <Typography>{`${otherUser.first_name} ${otherUser.last_name}`}</Typography>
        </Box>

        <Box sx={styles.messageList}>
          <List>
            {
              messages.length === 0 ? (
                <Typography color="textSecondary" sx={styles.noConversationText}>No messages yet</Typography>
              ) : (
                messages.map((msg, i) => (
                  <ListItem sx={styles.messageItem(msg.from_user_id, user.id)} key={msg.id}>
                    <Avatar
                      sx={styles.messageItemAvatar(switchUser[i])}
                      src={
                        msg.from_user_id === user.id
                          ? user.profile_picture.image
                          : otherUser.profile_picture.image
}
                    >
                      {msg.from_user_id === user.id
                        ? user.first_name.charAt(0) : otherUser.first_name.charAt(0)}
                    </Avatar>
                    <Box sx={styles.messageItemMessageBox(msg.from_user_id, user.id)}>
                      <Typography>{msg.message}</Typography>
                    </Box>
                  </ListItem>
                ))
              )
            }
          </List>
        </Box>

        <Box sx={styles.sendMessageContainer}>
          <TextField
            sx={styles.chatInput}
            label="New Message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            fullWidth
            rows={4}
          />
          <Button sx={styles.sendButton} onClick={handleSendMessage} variant="contained" color="primary">
            Send
          </Button>
        </Box>
      </Box>
    )
  );
}

ChatMessagesPage.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

export default connect(mapStateToProps)(ChatMessagesPage);
