import React from 'react';
import PropTypes from 'prop-types';
import SummaryCard from './summary-card';

function OrdersSummary({ orderCount }) {
  return (
    <SummaryCard
      title="Total Orders"
      value={orderCount}
      link="/a/order-management"
    />
  );
}

// Add prop validation
OrdersSummary.propTypes = {
  orderCount: PropTypes.number.isRequired,
};

export default OrdersSummary;
