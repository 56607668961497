import {
  SET_CHAT_THREADS,
  CLEAR_CHAT_THREADS,
  SET_CHAT_THREAD_SEEN,
  SET_NEW_CHAT_THREAD,
} from './types';

export const setChatThreads = (threads) => (dispatch) => {
  dispatch({
    type: SET_CHAT_THREADS,
    payload: threads,
  });
};

export const setChatThreadSeen = (threadId) => (dispatch) => {
  dispatch({
    type: SET_CHAT_THREAD_SEEN,
    payload: threadId,
  });
};

export const setNewChatThread = (thread) => (dispatch) => {
  dispatch({
    type: SET_NEW_CHAT_THREAD,
    payload: thread,
  });
};

export const clearChatThreads = () => (dispatch) => {
  dispatch({
    type: CLEAR_CHAT_THREADS,
    payload: {},
  });
};
