import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';

import UserReviews from '../../../review/user-reviews';
import StyledNotFoundComponent from '../../../common/styled-components/styled-not-found-component';
import { request } from '../../../common/utils';

const useStyles = (theme) => ({
  topContainer: {
    marginX: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginX: theme.spacing(10),
    },
  },
});

function VendorReviews({ user }) {
  const [reviews, setReviews] = useState([]);

  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    request(`/${user.id}/reviews`, 'GET')
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        console.error('Error fetching reviews:', error);
      });
  }, [user.id]);

  return (
    reviews.length === 0 ? (
      <StyledNotFoundComponent
        content={{
          title: 'No Reviews yet',
          message: 'You dont have any reviews yet. Renters can leave reviews only when an order is successfully completed.',
        }}
        showButton={false}
      />
    ) : (
      <Box sx={styles.topContainer}>
        <UserReviews reviews={reviews} />
      </Box>
    )
  );
}

VendorReviews.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
};

VendorReviews.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.user.userDetails,
});

export default connect(mapStateToProps)(VendorReviews);
