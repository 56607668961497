import React from 'react';
import PropTypes from 'prop-types';
import SummaryCard from './summary-card';

function CouponsSummary({ couponCount }) {
  return (
    <SummaryCard
      title="Total Coupons"
      value={couponCount}
      link="/a/coupon-management"
    />
  );
}

// Add prop validation
CouponsSummary.propTypes = {
  couponCount: PropTypes.number.isRequired,
};

export default CouponsSummary;
