const useStyles = (theme) => ({
  pageHeading: {
    padding: '16px 32px',
  },
  chatThreadsContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginX: 'auto',
    },
  },

  chatThreadItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 32px',
    cursor: 'pointer',
  },
  chatThreadItemRead: {
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    [theme.breakpoints.up('md')]: {
      borderLeft: '1px solid lightgrey',
      borderRight: '1px solid lightgrey',
    },
  },
  chatThreadItemUnread: {
    backgroundColor: theme.palette.primary.lightest,
  },
  chatThreadAvatar: {
    width: '50px',
    height: '50px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
  chatThreadNameAndMsg: {
    padding: '16px',
    flexGrow: 5,
  },
  chatThreadDate: {
    textAlign: 'right',
    maxWidth: '20%',
  },

  // Chat Messages
  chatMessagesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginX: 'auto',
    },
  },
  recipientDetailsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 0',
    borderBottom: '1px solid lightgrey',
  },
  recipientAvatar: {
    width: '50px',
    height: '50px',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    marginBottom: '16px',
  },
  messageList: {
    width: '100%',
    marginTop: 4,
    flexGrow: 1,
    height: 'calc(100vh - 420px)',
    overflowY: 'auto',
  },
  noConversationText: {
    textAlign: 'center',
  },
  messageItem: (msgUserId, userId) => ({
    width: '100%',
    display: 'flex',
    flexDirection: msgUserId === userId ? 'row-reverse' : 'row',
  }),
  messageItemAvatar: (switchUser) => ({
    width: '25px',
    height: '25px',
    visibility: switchUser ? '' : 'hidden',
  }),
  messageItemMessageBox: (msgUserId, userId) => ({
    bgcolor: msgUserId === userId ? 'lightblue' : 'lightgrey',
    padding: 1,
    borderRadius: 2,
    marginLeft: 1,
    marginRight: 1,
  }),
  sendMessageContainer: {
    width: '90%',
    marginY: 4,
    marginX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    padding: '0 16px',
  },
  chatInput: {
  },
  sendButton: {
  },
});

export default useStyles;
