import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Rating } from '@mui/material';
import PropTypes from 'prop-types';

import StyledTextField from './styled-textfield';

function StyledReviewDialog({
  open, onClose, onConfirm,
}) {
  const [star, setStar] = useState(0);
  const [comment, setComment] = useState('');

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title">Please leave a review</DialogTitle>
      <DialogContent>
        {/* {`For ${userToReview.first_name} ${userToReview.last_name}`} */}
        <Rating
          value={star}
          onChange={(event, newValue) => {
            setStar(newValue);
          }}
        />
        <StyledTextField
          label="Review"
          placeholder="Share how your experience was"
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => { onConfirm(star, comment); }}
          disabled={star === 0 || !comment.trim()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

StyledReviewDialog.propTypes = {
  open: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default StyledReviewDialog;
