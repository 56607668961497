// cookieUtils.js
const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    cookies.forEach((cookie) => {
      const trimmedCookie = cookie.trim();
      if (trimmedCookie.startsWith(`${name}=`)) {
        cookieValue = decodeURIComponent(trimmedCookie.substring(name.length + 1));
      }
    });
  }
  return cookieValue;
};

export default getCookie;
