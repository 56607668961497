import React, { useEffect, useState } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Helmet } from "react-helmet";
import ProductImages from './product-images';
import ProductDetails from './product-detail';
import { request } from '../common/utils'; // Adjust the import path as needed

function ProductPage() {
  const [productDetails, setProductDetails] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { id, user_name, product_name } = useParams();

  useEffect(() => {

    request(`/products/${id}`, 'GET')
      .then((res) => {
        setProductImages(res.data.images || []);
        setProductDetails(res.data);
        setIsLoading(false); // Data fetched, set loading to false
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false); // In case of an error, also stop loading
      });
  }, [location.search]); // Depend on location.search to re-run effect when query parameters change

  if (isLoading) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <div>
      <Helmet>
        <title>Rent {productDetails.name}</title>
        <meta name='description' content={`Rent ${productDetails.name} for ${productDetails.price} per day from ${productDetails.city}`} />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Grid item xs={12} md={12} marginLeft={4}>
            <Link to="/products">
              <Button startIcon={<ArrowBackIcon />}>
                Back to Search
              </Button>
            </Link>
          </Grid>
          <ProductImages images={productImages} />
        </Grid>
        <Grid item xs={12} md={7}>
          <ProductDetails details={productDetails} />
        </Grid>
      </Grid>
    </div>
  );
}

export default ProductPage;
