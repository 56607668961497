import React from 'react';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = (theme) => ({
  container: {
    marginY: 4,
  },
  heading: {
    mb: 4,
    textAlign: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  sectionHeader: {
    mt: 6,
    mb: 2,
    color: theme.palette.primary.main,
  },
  faqItem: {
    width: '100%',
  },
  faqItemSummary: {
    py: 1,
    px: 4,
    fontWeight: 'bold',
  },
  faqItemDetails: {
    px: 4,
  },
  faqItemDetailsContent: {
    textAlign: 'justify',
    lineHeight: 1.6,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemIcon: {
    minWidth: 'auto',
    marginRight: '20px',
    color: 'text.secondary',
  },
});

function FAQ() {
  const theme = useTheme();
  const styles = useStyles(theme);

  const faqs = [
    {
      id: 1,
      section: 'I want to lend my equipment',
      question: 'What is Rentovault?',
      answer:
        'Rentovault is a rental platform that connects individuals looking to lend their outdoor and photography equipment with those in need of borrowing such gear.',
    },
    {
      id: 2,
      section: 'I want to lend my equipment',
      question: 'What types of equipment can I lend on Rentovault?',
      answer: (
        <>
          Anything related to outdoor and photography equipment. For example drones, DSLR cameras, a
          variety of lenses, camping, paddling, and sports equipment etc. If you are uncertain, feel
          free to
          <a href="/contact">contact us</a>
          .
        </>
      ),
    },
    {
      id: 3,
      section: 'I want to lend my equipment',
      question: 'How does rentals work?',
      answer: (
        <List className={styles.list} sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {[
            'You become a vendor and list your equipment(s).',
            'Borrower searches for items and requests to borrow your equipment(s).',
            'Once you confirm, you and the borrower discuss & arrange for pickup.',
            'Once the rental period is over, borrower returns equipment(s).',
          ].map((text) => (
            <ListItem key={text} className={styles.listItem} disableGutters>
              <ListItemText className={styles.listItemText} primary={text} />
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      id: 4,
      section: 'I want to lend my equipment',
      question: 'How do payments work?',
      answer:
        'We accrue your earnings at the end of every month and pay you via e-transfer between the 1st and the 5th of the month. For your first payment, we’ll contact you directly to get the e-transfer details. We’re working on automating the process soon and making the payout interval more frequent.',
    },
    {
      id: 5,
      section: 'I want to lend my equipment',
      question: 'How much commission do you take?',
      answer: 'Our standard commission rate is 20%.',
    },
    {
      id: 6,
      section: 'I want to lend my equipment',
      question: 'Is my equipment insured during the rental period?',
      answer:
        'Rentovault assumes full responsibility to reimburse you, the lender, to cover damages or loss during the rental period provided that there is proper proof of damages. We recommend that you always check the equipment when handing off and receiving back the equipment and take pictures or videos as proof in case of disputes.',
    },
    {
      id: 7,
      section: 'I want to lend my equipment',
      question: 'How do I resolve disputes or damage claims?',
      answer: (
        <div>
          Rentovault offers full support to handle disputes and damage claims. Please reach out to
          us at
          {' '}
          <a href="mailto:info@rentovault.com">info@rentovault.com</a>
          {' '}
          or through our contact
          page. Make sure to add pictures and videos which will be used as proof of the damages.
        </div>
      ),
    },
    {
      id: 8,
      section: 'I want to borrow equipment',
      question: 'What is Rentovault?',
      answer:
        'Rentovault is a rental platform that connects individuals looking to lend their outdoor and photography equipment with those in need of borrowing such gear.',
    },
    {
      id: 9,
      section: 'I want to borrow equipment',
      question: 'What types of equipment can I find on Rentovault?',
      answer:
        'Anything related to outdoor and photography equipment. For example drones, DSLR cameras, a variety of lenses, camping, paddling, and sports equipment etc.',
    },
    {
      id: 10,
      section: 'I want to borrow equipment',
      question: 'How do I borrow what I need?',
      answer: (
        <List className={styles.list} sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {[
            'Search for the products that you need',
            'View the listings and place the order',
            'Once the lender confirms, discuss with them to set up a pickup time',
            'Return the equipment(s) at the end of the rental period',
          ].map((text) => (
            <ListItem key={text} className={styles.listItem} disableGutters>
              <ListItemText className={styles.listItemText} primary={text} />
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      id: 11,
      section: 'I want to borrow equipment',
      question: 'What mode of payments are accepted?',
      answer:
        'We accept all major credit cards (Visa, Mastercard, AMEX). Please note that cash or e-transfer are not accepted.',
    },
    {
      id: 12,
      section: 'I want to borrow equipment',
      question: 'What if I damage/lose the equipment?',
      answer:
        'The borrower is responsible for returning the equipment in the original condition and is liable for any damages during the rental period. You should always check the equipment when picking up and dropping off the equipment. We recommend that you take pictures or videos as proof in case of disputes. Rentovault reserves the right to charge your credit card for any damages or loss of equipment.',
    },
    {
      id: 13,
      section: 'I want to borrow equipment',
      question: 'Where and when do I pick up?',
      answer:
        'You will be able to see an approximate pick up location for any product. Once the lender accepts your order, you will be notified of the exact location. You can arrange the pickup and dropoff times with the lender directly from the messages page.',
    },
  ];

  const vendorFaqs = faqs.filter((faq) => faq.section === 'I want to lend my equipment');
  const borrowerFaqs = faqs.filter((faq) => faq.section === 'I want to borrow equipment');

  const renderFAQs = (faqList) => faqList.map((faq, index) => (
    <Accordion key={faq.id} sx={styles.faqItem}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={index} sx={styles.faqItemSummary}>
        <Typography variant="h6" color="textPrimary">
          {faq.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.faqItemDetails}>
        <Typography variant="body1" sx={styles.faqItemDetailsContent} color="textSecondary">
          {faq.answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  ));

  return (
    <Container maxWidth="md" sx={styles.container}>
      <Typography sx={styles.heading}>FAQs</Typography>
      <Box>
        <Typography variant="h4" sx={styles.sectionHeader}>
          I want to lend my equipment
        </Typography>
        {renderFAQs(vendorFaqs)}
      </Box>
      <Box>
        <Typography variant="h4" sx={styles.sectionHeader}>
          I want to borrow equipment
        </Typography>
        {renderFAQs(borrowerFaqs)}
      </Box>
    </Container>
  );
}

export default FAQ;
