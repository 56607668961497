import {
  SET_SEARCH_FILTER,
  CLEAR_SEARCH_FILTER,
} from '../actions/types';

const initialState = {
  searchFilter: {},
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: {
          ...state.searchFilter,
          [action.payload.field]: action.payload.value,
        },
      };
    case CLEAR_SEARCH_FILTER:
      return initialState;
    default:
      return state;
  }
};

export default productReducer;
