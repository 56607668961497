// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-gallery-slide {
    width: 75%;
    padding-left: 10%;
    padding-right: 10%;
  }

.image-gallery-thumbnail {
  width: 100px;
  height: 100px;
  padding: 8px 8px 8px 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/product-detail/styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;EACpB;;AAEF;EACE,YAAY;EACZ,aAAa;EACb,wBAAwB;AAC1B","sourcesContent":[".image-gallery-slide {\n    width: 75%;\n    padding-left: 10%;\n    padding-right: 10%;\n  }\n\n.image-gallery-thumbnail {\n  width: 100px;\n  height: 100px;\n  padding: 8px 8px 8px 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
