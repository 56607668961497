import { store } from '../redux/configureStore';
import { incrementUnreadNotificationCounter } from '../redux/actions/unread-notification-counter-action';
import getCookie from './cookie-utils'; // Adjust the import path as needed
import { setNewChatThread } from '../redux/actions/chat-actions';

class WebSocketService {
  constructor() {
    this.socket = null;
  }

  connect() {
    if (!this.socket) {
      const token = getCookie('token'); // Replace 'token' with your cookie name
      const url = `${process.env.REACT_APP_WEB_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN_NAME}`;
      this.socket = new WebSocket(`${url}/ws/message-listener/?token=${token}`);

      this.socket.onmessage = (event) => {
        if (!this.socket) {
          console.log('No socket found:', event);
        }
        console.log('WebSocket message received:', event);
        console.log('Specific data:', event.data);
        const data = JSON.parse(event.data);

        switch (data.type) {
          case 'NEW_NOTIFICATION':
            console.log('Processing NEW_NOTIFICATION:', event.data.message);
            store.dispatch(incrementUnreadNotificationCounter());
            // update redux store
            break;
          case 'NEW_MESSAGE':
            console.log('Processing NEW_MESSAGE:', data.message);
            store.dispatch(setNewChatThread(data.thread));
            // Handle incoming message
            break;
          default:
            break;
        }
      };

      this.socket.onclose = () => {
        console.log('WebSocket disconnected');
        // Optionally handle reconnection
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  sendMessage(message) {
    if (this.socket) {
      this.socket.send(JSON.stringify(message));
    }
  }
}

const instance = new WebSocketService();
export default instance;
