// CouponManagement.js
import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { request } from '../../common/utils';
import AdminTable from '../common/admin-table'; // Assuming this is the path to your DataTable component
import AdminModal from '../common/admin-modal'; // Assuming this is the path to your FormModal component
import styles from './styles';

const couponHeaders = [
  { id: 'code', label: 'Code' },
  { id: 'discount_type', label: 'Discount Type' },
  { id: 'amount', label: 'Amount' },
  { id: 'expiry_date', label: 'Expiry Date', type: 'datetime-local' },
  { id: 'is_single_use', label: 'Single Use?' },
];

const couponFields = [
  { id: 'code', label: 'Code', type: 'text' },
  {
    id: 'discount_type',
    label: 'Discount Type',
    type: 'select',
    options: [
      { value: 'dollar_value', label: 'Dollar Value' },
      { value: 'percentage', label: 'Percentage' },
    ],
  },
  { id: 'amount', label: 'Amount', type: 'number' },
  { id: 'expiry_date', label: 'Expiry Date', type: 'date' },
  { id: 'is_single_use', label: 'Single Use', type: 'checkbox' },
];

function CouponManagement() {
  const [coupons, setCoupons] = useState([]);
  const [currentCoupon, setCurrentCoupon] = useState(null); // For edit operations
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchCoupons = async () => {
    request('/a/coupons', 'GET')
      .then((res) => {
        console.log(res);
        setCoupons(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    // Fetch coupons when the component mounts
    fetchCoupons();
  }, []);

  const handleEdit = (coupon) => {
    setCurrentCoupon(coupon);
    setIsModalOpen(true);
  };

  const handleDelete = async (coupon) => {
    console.log('Deleting coupon:', coupon);
    request(
      `/a/coupon/${coupon.id}`,
      'DELETE',
    )
      .then((res) => {
        console.log('Coupon deleted:', res.data);
        fetchCoupons(); // Refresh the list after saving
      })
      .catch((err) => console.error('Failed to update coupon', err));
  };

  const handleAdd = () => {
    setCurrentCoupon(null); // Ensure modal is in "add" state
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentCoupon(null);
  };

  const handleSave = async (couponData) => {
    if (currentCoupon) {
      // Update operation
      console.log('Updating coupon:', currentCoupon, couponData);
      request(
        `/a/coupon/${currentCoupon.id}`,
        'PUT',
        {
          payload: couponData,
        },
      )
        .then((res) => {
          console.log('Coupon updated:', res.data);
          fetchCoupons(); // Refresh the list after saving
        })
        .catch((err) => console.error('Failed to update coupon', err));
    } else {
      // Add (create) operation
      console.log('Creating coupon:', couponData);
      request(
        '/a/coupon',
        'POST',
        {
          payload: couponData,
        },
      )
        .then((res) => {
          console.log('Coupon created:', res.data);
          fetchCoupons(); // Refresh the list after saving
        })
        .catch((err) => console.error('Failed to create coupon', err));
    }

    handleCloseModal(); // Close the modal after operation
  };

  return (
    <div>
      <AdminTable
        headers={couponHeaders}
        data={coupons}
        onEdit={handleEdit}
        onDelete={handleDelete}
        showEditButton
        showDeleteButton
      />
      <AdminModal
        open={isModalOpen}
        onClose={handleCloseModal}
        initialData={currentCoupon || {}}
        onSave={handleSave}
        fields={couponFields}
      />
      <Fab color="primary" aria-label="add" sx={styles.fab} onClick={handleAdd}>
        <AddIcon />
      </Fab>
    </div>
  );
}

export default CouponManagement;
