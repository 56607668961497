import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailIcon from '@mui/icons-material/Mail';
import styles from './styles';

function Footer() {
  const navigate = useNavigate();

  const redirectToContact = () => {
    navigate('/contact');
  };

  return (
    <Box sx={styles.footerContainer}>
      <Box sx={styles.contactAndTaglineContainer}>
        <Box sx={styles.aboutUsContainer}>
          <Typography component="h2" sx={styles.aboutUsHeader}>About Us</Typography>
          <Typography component="p" sx={{ ...styles.footerText, ...styles.aboutUsbodyMargin }}>
            You want it, we got it! Our aim is to
            make quality adventure / photography related rentals accessible for everyone.
            Whether you are renting a camera for a once in
            a lifetime trip,
            or camping equipment to figure out if the outdoors lifestyle is for you,
            we want to make sure you can get it affordably and hassle-free.
          </Typography>
          <Typography component="p" sx={{ ...styles.footerText, ...styles.aboutUsbodyMargin }}>
            RentoVault was founded by 3 adventure junkies who kept struggling on deciding
            on purchases for that next big trip -
            ‘is it worth spending so much?”, ‘will we use it more than once?’.
            If you face a similar dilemma, we feel you, and we got you.
            Stop worrying and rent away!
          </Typography>
        </Box>
        <Box sx={styles.socialLinksContainer}>
          <IconButton
            size="large"
            sx={styles.socialLinksIcon}
            onClick={() => window.open('https://www.facebook.com/profile.php?id=100081840524520')}
          >
            <FacebookIcon fontSize="large" />
          </IconButton>
          <IconButton
            size="large"
            sx={styles.socialLinksIcon}
            onClick={() => window.open('https://www.instagram.com/rentovault/')}
          >
            <InstagramIcon fontSize="large" />
          </IconButton>
          <IconButton
            size="large"
            sx={styles.socialLinksIcon}
            onClick={redirectToContact}
          >
            <MailIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      <Box sx={styles.copyrightContainer}>
        <Typography component="p" sx={styles.footerText}>Copyright © RentOvault 2022. All rights reserved.</Typography>
      </Box>
    </Box>
  );
}

export default Footer;
