import {
  SET_CHAT_THREADS,
  CLEAR_CHAT_THREADS,
  SET_CHAT_THREAD_SEEN,
  SET_NEW_CHAT_THREAD,
} from '../actions/types';

const initialState = {
  chatThreads: [],
  unreadCount: 0,
};

const ChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_THREADS:
      return {
        ...state,
        chatThreads: action.payload,
        unreadCount: action.payload.filter((thread) => !thread.latest_message_seen).length,
      };
    case CLEAR_CHAT_THREADS:
      return {
        ...initialState,
      };
    case SET_CHAT_THREAD_SEEN:
      return {
        ...state,
        chatThreads: state.chatThreads.map((thread) => {
          if (thread.id === action.payload) {
            return {
              ...thread,
              latest_message_seen: true,
            };
          }
          return thread;
        }),
        unreadCount: state.unreadCount >= 1 ? state.unreadCount - 1 : 0,
      };
    case SET_NEW_CHAT_THREAD: {
      const threadIndex = state.chatThreads.findIndex((thread) => thread.id === action.payload.id);

      let updatedChatThreads;
      if (threadIndex !== -1) {
        // Thread exists, update it
        updatedChatThreads = [...state.chatThreads];
        updatedChatThreads[threadIndex] = action.payload;
      } else {
        // Thread doesn't exist, add as new
        updatedChatThreads = [action.payload, ...state.chatThreads];
      }

      return {
        ...state,
        chatThreads: updatedChatThreads,
        unreadCount: updatedChatThreads.filter((thread) => !thread.latest_message_seen).length,
      };
    }
    default:
      return state;
  }
};

export default ChatReducer;
