import React from 'react';
import PropTypes from 'prop-types';
import NotificationItem from './notification-item';

function NotificationList({ notifications, setNotifications }) {
  return (
    <div>
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          setNotifications={setNotifications}
        />
      ))}
    </div>
  );
}

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setNotifications: PropTypes.func.isRequired,
};

export default NotificationList;
