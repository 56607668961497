const useStyles = (theme) => ({
  topContainer: {
    paddingX: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  photoUpload: {
    width: '150px',
    height: '150px',
    marginBottom: '32px',
  },
  passwordButtonContainer: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  passwordButton: {
  },
});

export default useStyles;
