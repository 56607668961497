import React, { useState, useEffect } from 'react';
import {
  Fab,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { request } from '../../common/utils';
import AdminTable from '../common/admin-table';
import AdminModal from '../common/admin-modal';
import styles from './styles';

const userHeaders = [
  { id: 'first_name', label: 'First Name' },
  { id: 'last_name', label: 'Last Name' },
  { id: 'email', label: 'Email' },
  { id: 'role', label: 'Role' },
  { id: 'created_at', label: 'Created At', type: 'datetime-local' },
  { id: 'updated_at', label: 'Updated At', type: 'datetime-local' },
];

const userFields = [
  { id: 'first_name', label: 'First Name', type: 'text' },
  { id: 'last_name', label: 'Last Name', type: 'text' },
  { id: 'email', label: 'Email', type: 'email' },
  { id: 'role', label: 'Role', type: 'text' }, // Add role field to modal
];

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');

  const fetchUsers = async () => {
    try {
      const res = await request('/a/users', 'GET');
      setUsers(res.data);
      setFilteredUsers(res.data);
    } catch (err) {
      console.error('Failed to fetch users:', err);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedRole) {
      setFilteredUsers(users.filter((user) => user.role === selectedRole));
    } else {
      setFilteredUsers(users);
    }
  }, [selectedRole, users]);

  const handleEdit = (user) => {
    setCurrentUser(user);
    setIsModalOpen(true);
  };

  const handleDelete = async (user) => {
    try {
      await request(`/a/user/${user.id}`, 'DELETE');
      fetchUsers(); // Refresh the list after deletion
    } catch (err) {
      console.error('Failed to delete user:', err);
    }
  };

  const handleAdd = () => {
    setCurrentUser(null); // Ensure modal is in "add" state
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentUser(null);
  };

  const handleSave = async (userData) => {
    try {
      if (currentUser) {
        // Update operation
        await request(`/a/user/${currentUser.id}`, 'PUT', { payload: userData });
      } else {
        // Add (create) operation
        await request('/a/user', 'POST', { payload: userData });
      }
      fetchUsers(); // Refresh the list after save
      handleCloseModal(); // Close the modal after operation
    } catch (err) {
      console.error('Failed to save user:', err);
    }
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="role-filter-label">Role</InputLabel>
        <Select
          labelId="role-filter-label"
          id="role-filter"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          displayEmpty
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="vendor">Vendor</MenuItem>
          {/* Add more roles as needed */}
        </Select>
      </FormControl>

      <AdminTable
        headers={userHeaders}
        data={filteredUsers}
        onEdit={handleEdit}
        onDelete={handleDelete}
        showEditButton
        showDeleteButton
      />
      <AdminModal
        open={isModalOpen}
        onClose={handleCloseModal}
        initialData={currentUser || {}}
        onSave={handleSave}
        fields={userFields}
      />
      <Fab color="primary" aria-label="add" sx={styles.fab} onClick={handleAdd}>
        <AddIcon />
      </Fab>
    </div>
  );
}

export default UserManagement;
