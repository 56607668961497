import React from 'react';
import { Box, useTheme } from '@mui/material';
import banner1 from '../../resource/banner/banner1.webp';
import banner2 from '../../resource/banner/banner2.webp';
import banner3 from '../../resource/banner/banner3.webp';
import banner4 from '../../resource/banner/banner4.webp';

const useStyles = (theme) => ({
  bannerContainer: {
    paddingX: 1,
    paddingY: 2,
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      paddingX: 15,
    },
  },
  bannerImage: {
    borderRadius: 2,
    width: '45%',
    aspectRatio: '1/1',
    objectFit: 'cover',
  },
});

function Banner() {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <>
      <Box sx={styles.bannerContainer}>
        <Box component="img" src={banner1} sx={styles.bannerImage} />
        <Box component="img" src={banner2} sx={styles.bannerImage} />
      </Box>
      <Box sx={styles.bannerContainer}>
        <Box component="img" src={banner3} sx={styles.bannerImage} />
        <Box component="img" src={banner4} sx={styles.bannerImage} />
      </Box>
    </>
  );
}

export default Banner;
